import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isAuthenticated, isExchangeAdmin, permit } from 'utils/auth';

function PrivateRoute({
  authState = true,
  component: Component,
  allowedPermissions,
  adminOnly = false,
  ...rest
}) {
  const isPermitted = () =>
    !authState ||
    !allowedPermissions ||
    allowedPermissions.length === 0 ||
    (isAuthenticated() &&
      permit(...allowedPermissions) &&
      (!adminOnly || isExchangeAdmin()));

  const canAccessRoute = () => isAuthenticated() === authState && isPermitted();

  return (
    <Route
      {...rest}
      render={props =>
        canAccessRoute() ? (
          <Component {...props} />
        ) : props.location.isExiting ? null : authState ? (
          <Redirect
            to={{
              pathname: isAuthenticated() ? '/unauthorized' : '/login',
              state: {
                redirectFrom: props.location,
                error: {
                  message: isAuthenticated()
                    ? 'Access denied'
                    : 'Please log into your account'
                }
              }
            }}
          />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
}

export default PrivateRoute;

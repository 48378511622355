import React, { useCallback } from 'react';
import { Jumbotron } from 'reactstrap';
import { H1 } from 'components/Heading';
import Button from 'components/Button';
import { isAuthenticated } from 'utils/auth';
import { Redirect } from 'react-router-dom';
import Helmet from 'react-helmet';
import keycloak from 'utils/keycloak';
import { isExchangeAdmin } from '../../utils/auth';

const propTypes = {};

const defaultProps = {};

function LoginPage({ history }) {
  const authenticated = isAuthenticated();

  const loginCallback = useCallback(() => {
    keycloak.login();
  }, []);

  if (authenticated) {
    if (isExchangeAdmin()) {
      return <Redirect to="/admin" />;
    }
    return <Redirect to={'/'} />;
  }

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div>
        <Jumbotron>
          <H1>Login</H1>
          <p className="lead">Please login to apply for membership</p>
          <hr className="my-4" />
          <Button
            className="mr-5"
            size="lg"
            color="primary"
            onClick={loginCallback}
          >
            Login
          </Button>
        </Jumbotron>
      </div>
    </>
  );
}

LoginPage.propTypes = propTypes;
LoginPage.defaultProps = defaultProps;

export default LoginPage;

import PropTypes from 'prop-types';

const {
  string,
  arrayOf,
  shape,
  bool,
  number,
  object,
  oneOf,
  oneOfType
} = PropTypes;

export const sectionShape = shape({
  uuid: string.isRequired,
  version: string.isRequired,
  category: string.isRequired,
  slug: string.isRequired,
  categorySlug: string.isRequired,
  path: string.isRequired,
  question: arrayOf(arrayOf(string)),
  nextSection: string,
  prevSection: string
});

export const questionnaireShape = shape({
  uuid: string.isRequired,
  createdAt: string.isRequired,
  version: string.isRequired,
  name: string.isRequired,
  sections: arrayOf(sectionShape)
});

export const sidebarQuestionnaireShape = shape({
  uuid: string,
  createdAt: string,
  version: string,
  name: string,
  sections: arrayOf(sectionShape)
});

export const localizationShape = shape({
  uuid: string.isRequired,
  locale: string.isRequired,
  body: string,
  format: string,
  instructions: string
});

export const responseTypeDefShape = shape({
  responseType: oneOf(['text', 'enum', 'attachment', 'map']).isRequired,
  branchKeys: oneOfType([arrayOf(string), object]),
  attachmentRefName: string,
  maximumFileSizeMB: number,
  allowedFileTypes: object,
  componentType: string,
  componentOptions: object,
  inputType: oneOf(['text', 'textarea']),
  options: shape({
    horizontal: bool,
    align: oneOf(['left', 'center', 'right'])
  })
});

export const questionBranchShape = shape({
  key: string.isRequired,
  questionUuid: string.isRequired,
  responseUuid: string.isRequired
});

export const questionResponseShape = shape({
  questionId: string.isRequired,
  responseId: string.isRequired
});

export const questionShape = shape({
  uuid: string.isRequired,
  version: string,
  identifier: string,
  defaultLocale: string.isRequired,
  localizations: arrayOf(localizationShape),
  responseTypeDef: responseTypeDefShape.isRequired,
  branchMapSummary: string,
  branchMap: arrayOf(questionBranchShape),
  summary: string,
  attachmentsAllowed: bool,
  required: oneOfType([bool, object])
});

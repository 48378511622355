import React, { useCallback, useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { useRequest, useMutation } from 'redux-query-react';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import {
  getQuestionnaire,
  getQuestionnaireByUuid
} from 'utils/questionnaireHelpers';
import SectionLink from 'components/SectionLink';
import { H1, H2, H3 } from 'components/Heading';
import { getQuestionnaireResponse } from 'selectors/questionnaireResponse';
import {
  questionnaireResponsesRequest,
  makeQuestionnaireResponseMutation
} from 'query-configs/questionnaireResponse';
import {
  STATUS_COMPLETED,
  STATUS_NOT_STARTED,
  STATUS_STARTED
} from 'utils/constants';
import Button from 'components/Button';
import Overview from './Overview';
import { selectSectionStatuses } from 'selectors/answer';
import { answersRequest } from 'query-configs/answer';
import ListGroup from 'reactstrap/es/ListGroup';
import ListGroupItem from 'reactstrap/es/ListGroupItem';
import DropdownToggle from 'reactstrap/es/DropdownToggle';
import UncontrolledDropdown from 'reactstrap/es/UncontrolledDropdown';
import DropdownMenu from 'reactstrap/es/DropdownMenu';
import DropdownItem from 'reactstrap/es/DropdownItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import {
  STATUS_APPROVED,
  STATUS_DENIED,
  STATUS_NEEDS_ATTENTION,
  STATUS_SUBMITTED,
  STATUS_UNSUBMITTED
} from '../../utils/constants';
import questionnaires from 'questionnaire';

const sectionStatusIcons = {
  [STATUS_COMPLETED]: faCheckSquare,
  [STATUS_NOT_STARTED]: faSquare,
  [STATUS_STARTED]: faSquare
};

const sectionStatusColors = {
  [STATUS_COMPLETED]: 'light',
  [STATUS_NOT_STARTED]: null,
  [STATUS_STARTED]: null
};

const statusLabels = {
  [STATUS_UNSUBMITTED]: 'In Progress',
  [STATUS_SUBMITTED]: 'In Review',
  [STATUS_NEEDS_ATTENTION]: 'Needs Attention',
  [STATUS_DENIED]: 'Denied',
  [STATUS_APPROVED]: 'Approved'
};

function HomePage({ history }) {
  const [selectedQuestionnaireUuid, setSelectedQuestionnaireUuid] = useState(
    ''
  );
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState('');
  const [selectedQuestionnaireTitle, setSelectedQuestionnaireTitle] = useState(
    ''
  );

  const questionnaireResponse = useSelector(getQuestionnaireResponse);

  let savedQuestionnaire = {};
  if (questionnaireResponse) {
    savedQuestionnaire = getQuestionnaireByUuid(
      questionnaireResponse.questionnaireUuid
    );
  }

  const questionnaireFunction = useCallback(
    (key, data) => {
      setSelectedQuestionnaireUuid(data.uuid);
      setSelectedQuestionnaire(getQuestionnaireByUuid(data.uuid));
      setSelectedQuestionnaireTitle(data.title);
      window.localStorage.setItem('questionnaireUuid', data.uuid);
    },
    [selectedQuestionnaireUuid, selectedQuestionnaire]
  );

  const questionnaire = !isEmpty(savedQuestionnaire)
    ? savedQuestionnaire
    : selectedQuestionnaire;

  const sectionStatuses = useSelector(
    selectSectionStatuses(questionnaire.uuid)
  );

  const [{ status, isFinished }] = useRequest(
    questionnaireResponsesRequest(
      selectedQuestionnaireUuid,
      questionnaireResponse && questionnaireResponse._id,
      true
    )
  );

  const [{ isFinished: answersRequestIsFinished }] = useRequest(
    answersRequest(questionnaireResponse && questionnaireResponse._id)
  );

  // eslint-disable-next-line no-unused-vars
  const [_, makeQuestionnaireResponse] = useMutation(questionnaireResponse =>
    makeQuestionnaireResponseMutation(questionnaireResponse)
  );

  const firstSection =
    !isEmpty(questionnaire) &&
    questionnaire.sections.length > 0 &&
    questionnaire.sections[0];

  const startApplicationCallback = useCallback(() => {
    makeQuestionnaireResponse({
      questionnaireUuid: selectedQuestionnaireUuid
    }).then(result => {
      if (result.status !== 200) {
        console.error(result.text);
        return result;
      }

      history.push(firstSection.path);
    });
  }, [makeQuestionnaireResponse, history, firstSection.path]);

  const nextSection =
    (!isEmpty(questionnaire) &&
      sectionStatuses &&
      find(
        questionnaire.sections,
        section =>
          sectionStatuses[section.uuid] &&
          sectionStatuses[section.uuid] !== STATUS_COMPLETED
      )) ||
    firstSection;

  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <React.StrictMode>
        <div>
          <H1>Membership Application</H1>
          {!questionnaireResponse && (
            <>
              <H2>Please select an application from the dropdown below:</H2>
              <UncontrolledDropdown className="dropdown-section">
                <DropdownToggle caret className="btn-primary">
                  {selectedQuestionnaireTitle || '-- Select A Questionnaire --'}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem disabled>
                    -- Select A Questionnaire --
                  </DropdownItem>
                  {questionnaires.map(questionnaire => {
                    return (
                      <DropdownItem
                        value={questionnaire.uuid}
                        key={questionnaire.uuid}
                        onClick={e => {
                          questionnaireFunction('questionnaireUuid', {
                            uuid: e.target.value,
                            title: questionnaire.name
                          });
                        }}
                        active={
                          questionnaire.uuid === selectedQuestionnaireUuid
                        }
                      >
                        {questionnaire.name}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          )}
          {questionnaireResponse && (
            <>
              <p>
                Welcome! You can check your application status below. To pick up
                at the last unfinished step, please click on the Continue button
                below.
              </p>
              {answersRequestIsFinished && (
                <div className="my-5 w-75">
                  <H3 as="h2">
                    Current Status: {statusLabels[questionnaireResponse.status]}
                  </H3>
                  {questionnaireResponse.status === STATUS_NEEDS_ATTENTION && (
                    <p>{questionnaireResponse.adminNotes}</p>
                  )}
                  <div className="my-3">
                    <ListGroup>
                      {questionnaire.sections.map(section => {
                        const status =
                          (sectionStatuses && sectionStatuses[section.uuid]) ||
                          STATUS_NOT_STARTED;

                        return (
                          <ListGroupItem
                            key={section.uuid}
                            className="d-flex justify-content-between align-items-center"
                            color={sectionStatusColors[status]}
                          >
                            {status === STATUS_COMPLETED ||
                            status === STATUS_STARTED ? (
                              <Link
                                className="text-reset"
                                to={section.path.replace(
                                  ':questionnaireId',
                                  questionnaireResponse &&
                                    questionnaireResponse._id
                                )}
                              >
                                {section.name}
                              </Link>
                            ) : (
                              section.name
                            )}
                            <FontAwesomeIcon
                              size="lg"
                              icon={sectionStatusIcons[status]}
                            />
                          </ListGroupItem>
                        );
                      })}
                      {/*<ListGroupItem*/}
                      {/*  className="d-flex justify-content-between align-items-center"*/}
                      {/*  color={sectionStatusColors[status]}*/}
                      {/*>*/}
                      {/*  Read and Agree to Exchange Disclosures and Agreements*/}
                      {/*  <FontAwesomeIcon*/}
                      {/*    size="lg"*/}
                      {/*    icon={sectionStatusIcons[STATUS_NOT_STARTED]}*/}
                      {/*  />*/}
                      {/*</ListGroupItem>*/}
                    </ListGroup>
                  </div>
                  <div className="text-right">
                    <SectionLink
                      uuid={nextSection.uuid}
                      questionnaireId={
                        questionnaireResponse && questionnaireResponse._id
                      }
                      prefix=""
                      questionnaireUuid={
                        selectedQuestionnaireUuid ||
                        questionnaireResponse.questionnaireUuid
                      }
                    >
                      Continue
                    </SectionLink>
                  </div>
                </div>
              )}

              <H2>Membership Application Overview</H2>
            </>
          )}

          <Overview />

          <div className="text-right my-5">
            {firstSection && isFinished && !questionnaireResponse && (
              <Button
                color="primary"
                size="lg"
                onClick={startApplicationCallback}
                className="d-inline-flex w-50 justify-content-between align-items-center"
              >
                <span className="mr-5">Start an Application</span>{' '}
                <FontAwesomeIcon
                  className="ml-4"
                  size="2x"
                  icon={faCaretRight}
                />
              </Button>
            )}
          </div>
        </div>
      </React.StrictMode>
    </>
  );
}

export default HomePage;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, PopoverBody } from 'reactstrap';
import PopoverHeader from 'reactstrap/es/PopoverHeader';

const StyledIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

const propTypes = {
  id: PropTypes.string.isRequired,
  header: PropTypes.string,
  iconSize: PropTypes.oneOf(['xs', 'sm', 'lg', '2x', '3x', '5x', '7x', '10x']),
  className: PropTypes.string,
  children: PropTypes.node
};

const defaultProps = {};

function HelpTooltip({ id, header, iconSize, className, children }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <StyledIcon
        id={id}
        icon={faQuestionCircle}
        className={className}
        size={iconSize}
      />
      <Popover
        isOpen={isOpen}
        target={id}
        toggle={() => setIsOpen(!isOpen)}
        trigger="legacy"
      >
        {header && <PopoverHeader>{header}</PopoverHeader>}
        <PopoverBody>{children}</PopoverBody>
      </Popover>
    </>
  );
}

HelpTooltip.propTypes = propTypes;
HelpTooltip.defaultProps = defaultProps;

export default HelpTooltip;

import React from 'react';
import { Route } from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute';
import NotFound from './NotFound';
import UnauthorizedPage from './UnauthorizedPage';
import HomePage from './HomePage';
import SectionPage from './SectionPage';
import LoginPage from './LoginPage';
import LogoutPage from './LogoutPage';
import AgreementsPage from './AgreementsPage';
import SubmitConfirmationPage from './SubmitConfirmationPage';
import HelpSupportPage from './HelpSupportPage';
import { PERMISSIONS } from 'utils/auth';
import Admin from './Admin';

export default [
  <Route component={LoginPage} exact path="/login" key="login" />,
  <Route component={LogoutPage} exact path="/logout" key="logout" />,
  <PrivateRoute
    component={HomePage}
    exact
    path="/"
    allowedPermissions={[PERMISSIONS.view, PERMISSIONS.manage]}
    key="homepage"
  />,
  <PrivateRoute
    component={Admin}
    path="/admin"
    key="admin"
    allowedPermissions={[PERMISSIONS.view, PERMISSIONS.manage]}
    adminOnly
  />,
  <PrivateRoute
    component={AgreementsPage}
    exact
    path="/agreements"
    allowedPermissions={[PERMISSIONS.view, PERMISSIONS.manage]}
    key="agreements"
  />,
  <PrivateRoute
    component={SectionPage}
    path={`/application/:questionnaireId/section/:category/:slug`}
    allowedPermissions={[PERMISSIONS.view, PERMISSIONS.manage]}
    key="section_page"
    exact={true}
  />,
  <PrivateRoute
    component={SubmitConfirmationPage}
    exact
    path="/submit-confirmation"
    allowedPermissions={[PERMISSIONS.manage]}
    key="submitConfirmation"
  />,
  <PrivateRoute
    component={SectionPage}
    allowedPermissions={[PERMISSIONS.view, PERMISSIONS.manage]}
    path={`/application/:questionnaireId`}
    key="section_lookup_page"
  />,
  <PrivateRoute
    component={SectionPage}
    path={`/application/:questionnaireId/section/:category/:slug`}
    allowedPermissions={[PERMISSIONS.view, PERMISSIONS.manage]}
    key="section_lookup_page"
  />,
  <Route
    component={HelpSupportPage}
    exact
    path="/help"
    key="help_support_page"
  />,
  <Route
    component={UnauthorizedPage}
    exact
    path="/unauthorized"
    key="page_unauthorized"
  />,
  <Route component={NotFound} key="page_not_found" />
];

import React from 'react';
import PropTypes from 'prop-types';
import { getQuestion } from 'utils/questionnaireHelpers';
import { questionResponseShape } from 'utils/shapes';
import { Alert } from 'reactstrap';
import Question from 'components/Question';

const propTypes = {
  questions: PropTypes.arrayOf(questionResponseShape)
};

const defaultProps = {};

function Questions({ questions }) {
  return (
    <div>
      {questions.map(item => {
        const question = getQuestion(item.questionId);

        if (!question) {
          return (
            <Alert color="danger">
              Missing question definition: {item.questionId}
            </Alert>
          );
        }

        return (
          <Question
            question={question}
            key={`${item.questionId}/${item.responseId}`}
            responseId={item.responseId}
          />
        );
      })}
    </div>
  );
}

Questions.propTypes = propTypes;
Questions.defaultProps = defaultProps;

export default Questions;

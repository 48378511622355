import keyBy from 'lodash/keyBy';
import { API_URL, QUESTIONNAIRE_UUID } from 'utils/constants';
import { getToken } from 'utils/auth';
import { httpMethods } from 'redux-query';

export const RESOURCE_URL = API_URL + '/api/admin/questionnaire-responses';

const buildURLQuery = obj =>
  Object.entries(obj)
    .map(pair => pair.map(encodeURIComponent).join('='))
    .join('&');

// Query config for retrieving all questionnaire responses
export const questionnaireResponsesRequest = (
  questionnaireUuid,
  force = false,
  params = { query: {} }
) => ({
  url: `${RESOURCE_URL}`,
  options: {
    headers: {
      'x-auth': getToken()
    }
  },
  force,
  body: {},
  transform: body => {
    const selected = body.find(item => item.questionnaireUuid);
    return {
      allQuestionnaireResponse: selected ? selected._id : null,
      allQuestionnaireResponsesById: keyBy(body, '_id')
    };
  },
  update: {
    allQuestionnaireResponse: (oldValue, newValue) => newValue,
    allQuestionnaireResponsesById: (oldValue, newValue) =>
      keyBy(newValue, '_id')
  }
});

// Query config for making a new questionnaire response
export const makeQuestionnaireResponseMutation = ({ questionnaireUuid }) => ({
  url: RESOURCE_URL,
  options: {
    headers: {
      'x-auth': getToken()
    }
  },
  body: {
    questionnaireUuid
  },
  transform: body => ({
    allQuestionnaireResponse: body._id,
    allQuestionnaireResponsesById: {
      [body._id]: body
    }
  }),
  update: {
    allQuestionnaireResponse: (oldValue, newValue) => newValue,
    allQuestionnaireResponsesById: (oldValue, newValue) => ({
      ...oldValue,
      ...newValue
    })
  }
});

export const updateQuestionnaireResponseMutation = questionnaireResponse => {
  return {
    url: RESOURCE_URL + `/${questionnaireResponse._id}`,
    options: {
      method: httpMethods.PUT,
      headers: {
        'x-auth': getToken()
      }
    },
    body: {
      questionnaireResponse
    }
  };
};

import filter from 'lodash/filter';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import every from 'lodash/every';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import { getAllQuestionsKeyedByUuid } from 'utils/questionnaireHelpers';
import {
  MAPPED_RESPONSE_TYPES,
  TYPE_ATTACHMENT,
  TYPE_ENUM,
  TYPE_MAPPED_RESPONSE,
  TYPE_TEXT,
  CONSTRAINT_TYPE_LENGTH,
  CONSTRAINT_TYPE_ALPHANUMERIC,
  CONSTRAINT_TYPE_NUMERIC,
  CONSTRAINT_TYPE_REGEX,
  REGEX_ALPHANUMERIC,
  REGEX_NUMERIC,
  CONSTRAINT_TYPE_REQUIRED
} from 'utils/constants';
import { CONSTRAINT_TYPE_EMAIL, REGEX_EMAIL } from './constants';
import { isObject } from './functions';
import { isMappedRequired } from '../hooks/useIsRequired';

const required = value => (value ? undefined : 'Required');

const maybeRequired = (value, question, answers) =>
  question && question.required
    ? isObject(question.required)
      ? isMappedRequired(
          question,
          find(
            answers,
            answer => answer.questionUuid === question.required.questionUuid
          )
        )
        ? required(value)
        : undefined
      : required(value)
    : undefined;

const arrayNotEmpty = value =>
  Array.isArray(value) && value.length > 0 ? undefined : 'Required';

const representativeRolesAssigned = (value, question) => {
  return value &&
    every(
      question.responseTypeDef.templateJSON.contactMethods,
      (method, methodId) => {
        return (
          !method.required ||
          find(value, u => {
            return find(
              u.positions,
              p => p.questionUuid === methodId && p.checked === true
            );
          })
        );
      }
    )
    ? undefined
    : 'Each role must be assigned to a representative';
};

const makePathValidators = (path, validators) =>
  validators.map(validator => (value, question) =>
    validator(get(value, path), question)
  );

const addressEntryValidatorsMap = {
  addressPurpose: [required],
  country: [required],
  addressLine1: [required],
  city: [required],
  province: [],
  zipCode: [required]
};

const contactValidatorsMap = {
  areaCode: [required],
  phoneNumber: [required],
  extension: [],
  email: [required],
  website: []
};

function makePathValidatorsFromMap(pathMap, basePath = null) {
  basePath = basePath ? `${basePath}.` : '';

  return reduce(
    pathMap,
    (result, validators, path) => {
      return result.concat(makePathValidators(basePath + path, validators));
    },
    []
  );
}

const constraintLength = (value, question, options) => {
  if (value && value.length < options.min.value) {
    return options.min.message;
  } else if (value && value.length > options.max.value) {
    return options.max.message;
  }

  return undefined;
};

const constraintAlphaNumeric = (value, question, options) => {
  return constraintRegex(
    value,
    question,
    Object.assign({}, options, {
      pattern: REGEX_ALPHANUMERIC
    }),
    false
  );
};

const constraintNumeric = (value, question, options) => {
  return constraintRegex(
    value,
    question,
    Object.assign({}, options, {
      pattern: REGEX_NUMERIC
    }),
    false
  );
};

const constraintEmail = (value, question, options) => {
  return constraintRegex(
    value,
    question,
    Object.assign({}, options, {
      pattern: REGEX_EMAIL
    }),
    false
  );
};

const constraintRegex = (value, question, options, condition = true) => {
  const pattern = new RegExp(options.pattern);

  if (!value) {
    return undefined;
  }

  if (pattern.test(value) === condition) {
    return options.message;
  }

  return undefined;
};

const constraintRequired = (value, question, options) => {
  if (!value) {
    return 'Required';
  }

  return undefined;
};

const addressEntryValidators = basePath => {
  return makePathValidatorsFromMap(addressEntryValidatorsMap, basePath);
};

const addressesValidator = (value, question) => {
  if (!Array.isArray(value)) {
    return 'At least one Legal Address required';
  }

  // Check for at least one legal address.
  if (!value.find(address => address.addressPurpose === 'primary')) {
    return 'A Legal Address is required';
  }

  const validators = addressEntryValidators();

  const results = filter(
    value.reduce((total, address) => {
      return validators.map(validator => validator(address, question));
    }, []),
    v => !isNil(v)
  );

  return results.length > 0 ? results : undefined;
};

const contactValidators = question => {
  return makePathValidatorsFromMap(contactValidatorsMap);
};

const representativesValidators = question => {
  return [arrayNotEmpty, representativeRolesAssigned];
};

function getMappedResponseValidators(question) {
  let validators = [maybeRequired];

  switch (question.responseTypeDef.componentType) {
    case MAPPED_RESPONSE_TYPES.address:
      validators = validators.concat([addressesValidator]);
      break;
    case MAPPED_RESPONSE_TYPES.contact:
      validators = validators.concat(contactValidators(question));
      break;
    case MAPPED_RESPONSE_TYPES.representatives:
      validators = validators.concat(representativesValidators(question));
      break;
    default:
      break;
  }

  return validators;
}

function getAttachmentValidators(question) {
  return [maybeRequired];
}

function getEnumValidators(question) {
  return [maybeRequired];
}

function getTextValidators(question) {
  return [maybeRequired];
}

const responseTypeValidatorsMap = {
  [TYPE_TEXT]: getTextValidators,
  [TYPE_ENUM]: getEnumValidators,
  [TYPE_ATTACHMENT]: getAttachmentValidators,
  [TYPE_MAPPED_RESPONSE]: getMappedResponseValidators
};

const constraintTypeValidatorsMap = {
  [CONSTRAINT_TYPE_LENGTH]: constraintLength,
  [CONSTRAINT_TYPE_ALPHANUMERIC]: constraintAlphaNumeric,
  [CONSTRAINT_TYPE_NUMERIC]: constraintNumeric,
  [CONSTRAINT_TYPE_REGEX]: constraintRegex,
  [CONSTRAINT_TYPE_EMAIL]: constraintEmail,
  [CONSTRAINT_TYPE_REQUIRED]: constraintRequired
};

export function getQuestionValidators(question, questions) {
  return [
    ...(question && question.responseTypeDef
      ? responseTypeValidatorsMap[question.responseTypeDef.responseType](
          question
        )
      : []),
    ...getQuestionConstraints(question)
  ];
}

export function getQuestionConstraints(question) {
  if (
    !question ||
    !question.responseTypeDef ||
    !question.responseTypeDef.constraints
  ) {
    return [];
  }

  return question.responseTypeDef.constraints.map(element => {
    return (answer, question) =>
      constraintTypeValidatorsMap[element.type](
        answer,
        question,
        element.options
      );
  });
}

export function validateAnswer(
  answer,
  question = null,
  answers,
  validationLogic = defaultValidationLogic
) {
  const questionsByUuid = getAllQuestionsKeyedByUuid();

  if (!question) {
    question = questionsByUuid[answer.questionUuid];
  }

  if (!question) {
    throw new Error(
      `Answer references question with uuid "${answer &&
        answer.questionUuid}" that does not exist.`
    );
  }

  if (question.responseTypeDef.responseType === 'map') {
    switch (question.responseTypeDef.componentType) {
      case 'address':
        validationLogic = addressValidationLogic;
        break;
      case 'representatives':
        validationLogic = representativesValidationLogic;
        break;
      default:
        break;
    }
  }

  const baseValidators = getQuestionValidators(question, questionsByUuid);

  let results = filter(
    validationLogic(baseValidators, question, answer, answers),
    v => !isNil(v)
  );

  if (
    question.branchMap &&
    Array.isArray(question.branchMap) &&
    question.branchMap.length
  ) {
    question.branchMap.forEach(map => {
      if (answer && answer.responseData === map.key) {
        const response = validateAnswer(
          find(answers, a => a.responseUuid === map.responseUuid),
          find(questionsByUuid, q => q.uuid === map.questionUuid),
          answers
        );
        if (response) {
          results.push(...response);
        }
      }
    });
  }

  return results.length > 0 ? results : undefined;
}

const defaultValidationLogic = (baseValidators, question, answer, answers) => {
  return baseValidators.map(validator =>
    validator(answer && answer.responseData, question, answers)
  );
};

const representativesValidationLogic = (baseValidators, question, answer) => {
  const validation = validateRepresentativesAnswer(
    question.responseTypeDef.templateJSON.contactMethods,
    answer && answer.responseData
  );

  return Object.keys(validation).map(key => {
    return validation[key] ? undefined : true;
  });
};

export const validateRepresentativesAnswer = (contactMethods, newValue) => {
  if (!Array.isArray(newValue) || newValue.length === 0) {
    return { valid: false };
  }

  let response = [];

  Object.keys(contactMethods).forEach(c => {
    response[c] = false;
  });

  newValue &&
    newValue.forEach(row => {
      row.positions.forEach(position => {
        if (position.checked) {
          response[position.questionUuid] = true;
        }
      });
    });

  return response;
};

const addressValidationLogic = (baseValidators, question, answer) => {
  const responseTypeDef = question.responseTypeDef;

  const validators = flatten(
    responseTypeDef.responseType === 'map' &&
      responseTypeDef.templateJSON &&
      responseTypeDef.templateJSON.questions
      ? Object.keys(responseTypeDef.templateJSON.questions).map(q => ({
          [q]:
            q === 'state'
              ? [() => undefined]
              : getQuestionValidators(responseTypeDef.templateJSON.questions[q])
        }))
      : []
  );

  let response = [];

  const errors = validators.map(validator => {
    if (validator instanceof Function) {
      return validator(answer && answer.responseData, question);
    } else {
      return [].concat.apply(
        [],
        Object.keys(validator).map(valKey => {
          const subValidators = validator[valKey];

          return flatten(
            subValidators.map(subValidator => {
              return (
                answer &&
                answer.responseData.map(answerResponse => {
                  const result = subValidator(answerResponse[valKey]);

                  if (!result) {
                    return undefined;
                  }

                  return {
                    key: valKey,
                    uuid: answerResponse.uuid,
                    value: result
                  };
                })
              );
            })
          );
        })
      );
    }
  });

  errors.forEach(e => {
    if (Array.isArray(e)) {
      e.forEach(s => {
        if (s !== undefined) {
          response.push(s);
        }
      });
    }
  });

  return response;
};

import styled from 'styled-components';
import { rem } from 'polished';

const BaseHeader = styled.span`
  line-height: 1.2;
  font-weight: ${props => props.theme.fontLight};
`;

export const H1 = styled(BaseHeader).attrs(() => ({ as: 'h1' }))`
  font-size: ${rem(32)};
  margin-bottom: 1em;
`;

export const H2 = styled(BaseHeader).attrs(() => ({ as: 'h2' }))`
  font-size: ${rem(26)};
  margin-bottom: 1.5rem;
`;

export const H3 = styled(BaseHeader).attrs(() => ({ as: 'h3' }))`
  font-size: ${rem(22)};
  font-weight: ${props => props.theme.fontSemibold};
  margin-bottom: 0.5rem;
`;

export const H4 = styled(BaseHeader).attrs(() => ({ as: 'h4' }))`
  font-size: ${rem(18)};
  font-weight: ${props => props.theme.fontSemibold};
`;

const Heading = {
  H1,
  H2,
  H3,
  H4
};

export default Heading;

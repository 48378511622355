/** global: localStorage */
import keycloak from './keycloak';

const KEYCLOAK_STORAGE_KEY = 'smallx_keycloak';
const EXCHANGE_ADMIN_FIRM_TYPE = 'EXCHANGE';
const PERMISSION_VIEW_ONBOARDING = 'view-onboarding';
const PERMISSION_MANAGE_ONBOARDING = 'manage-onboarding';

export const PERMISSIONS = {
  view: PERMISSION_VIEW_ONBOARDING,
  manage: PERMISSION_MANAGE_ONBOARDING
};

export const getToken = () => keycloak.token;

export const removeToken = () => keycloak.clearToken();

export const isAuthenticated = () => !!keycloak.authenticated;

export const isExchangeAdmin = () => {
  if (!keycloak.authenticated) {
    return false;
  }

  const { firm_type } = keycloak.tokenParsed;

  return firm_type && firm_type === EXCHANGE_ADMIN_FIRM_TYPE;
};

const getUserPermissions = () => {
  if (!keycloak.authenticated) {
    return [];
  }

  return keycloak.tokenParsed.permissions || [];
};

const hasPermission = permission =>
  getUserPermissions().includes(permission.toLowerCase());

export const permit = (...allowedPermissions) =>
  !!allowedPermissions.find(hasPermission);

export const canViewOnboarding = () =>
  hasPermission(PERMISSIONS.view) || canManageOnboarding();

export const canManageOnboarding = () => hasPermission(PERMISSIONS.manage);

export const getUserId = () => keycloak.subject;

export const authHeaders = () => ({
  Authorization: `Bearer ${getToken()}`
});

export const refreshToken = () =>
  new Promise((resolve, reject) => {
    keycloak
      .updateToken(5)
      .success(refreshed => {
        if (refreshed || !hasKeycloakValues()) {
          setKeycloakValues();
        }

        resolve(refreshed);
      })
      .error(err => {
        clearSessionStorage();
        reject(err);
      });
  });

export const setKeycloakValues = () => {
  localStorage.setItem(
    KEYCLOAK_STORAGE_KEY,
    JSON.stringify({
      token: keycloak.token,
      refreshToken: keycloak.refreshToken,
      idToken: keycloak.idToken,
      timeSkew: keycloak.timeSkew
    })
  );
};

export const getKeycloakValues = () => {
  const values = localStorage.getItem(KEYCLOAK_STORAGE_KEY);

  return values ? JSON.parse(values) : {};
};

export const hasKeycloakValues = () =>
  !!localStorage.getItem(KEYCLOAK_STORAGE_KEY);

export const removeKeycloakValues = () => {
  localStorage.removeItem(KEYCLOAK_STORAGE_KEY);
};

export const keycloakLogout = options => {
  keycloak.logout(options);
};

export const clearSessionStorage = () => {
  removeKeycloakValues();
  removeToken();
};

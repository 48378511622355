import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { questionShape } from 'utils/shapes';
import { FormGroup } from 'reactstrap';
import QuestionBody from './QuestionBody';
import QuestionLabel from './QuestionLabel';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import filter from 'lodash/filter';
import LoadingIndicator from '../../LoadingIndicator';
import uuid from 'uuid/v4';
import { useSelector } from 'react-redux';
import { getQuestionnaireResponse } from 'selectors/questionnaireResponse';
import AttachmentQuestionDownload from './AttachmentQuestionDownload';

const propTypes = {
  question: questionShape.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  answer: PropTypes.object.isRequired,
  saving: PropTypes.string,
  disabled: PropTypes.bool
};

const defaultProps = {};

const DropZone = styled.div`
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed grey;
`;

const DropZoneText = styled.div`
  padding: 0;
`;

const InvalidFileMessage = styled.p`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: red;
`;

function AttachmentQuestion({
  question,
  value,
  onSave,
  answer,
  saving,
  disabled
}) {
  const {
    responseTypeDef: { maximumFileSizeMB, allowedFileTypes }
  } = question;
  const [isUploading, setIsUploading] = useState(false);
  const [files, setFiles] = useState(
    (value && !Array.isArray(value) ? [value] : value) || []
  );
  const questionnaireResponse = useSelector(getQuestionnaireResponse);

  useEffect(() => {
    if ((value || []) !== files) {
      setFiles(value || []);
    }
  }, [value]);

  const maxSize = maximumFileSizeMB * 1000000;

  const onDrop = useCallback(
    acceptedFiles => {
      setIsUploading(true);

      let loadedFiles = 0;

      let newFiles = [...files];

      acceptedFiles.forEach(file => {
        newFiles.push({
          ...file,
          loading: true
        });
      });

      setFiles(newFiles);

      acceptedFiles.forEach(file => {
        const reader = new FileReader();

        reader.onabort = () => console.warn('file reading was aborted');
        reader.onerror = () => console.error('file reading has failed');

        reader.onload = () => {
          const data = {
            name: file.name,
            size: file.size,
            content: btoa(reader.result),
            type: 'attachment',
            loading: true
          };

          newFiles.splice(-1, 1, data);
          loadedFiles++;

          if (loadedFiles === acceptedFiles.length) {
            onSave(newFiles).then(d => {
              setIsUploading(false);
            });
          }
        };
        reader.readAsBinaryString(file);
      });
    },
    [onSave, files, setFiles]
  );

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    rejectedFiles
  } = useDropzone({
    multiple: false,
    maxSize,
    minSize: 1,
    accept: Object.values(allowedFileTypes).join(', '),
    onDrop: onDrop,
    disabled: disabled
  });

  const invalid =
    rejectedFiles &&
    rejectedFiles.length > 0 &&
    rejectedFiles[0].size > maxSize;

  return (
    <FormGroup>
      <QuestionLabel question={question} for={question.uuid} saving={saving}>
        {question.summary}
      </QuestionLabel>
      <QuestionBody question={question} />
      {!disabled && (
        <DropZone {...getRootProps()} disabled={isUploading}>
          <input
            {...getInputProps({
              id: question.uuid,
              name: question.uuid,
              disabled: disabled
            })}
          />
          <DropZoneText>
            Drop files to insert them on the page or click to select files
          </DropZoneText>
        </DropZone>
      )}
      {files.length > 0 &&
        files.map(file => (
          <li key={uuid()}>
            {file.loading && <LoadingIndicator small />}
            <AttachmentQuestionDownload
              questionnaireResponseId={
                questionnaireResponse && questionnaireResponse._id
              }
              answerId={answer && answer._id}
              fileKey={file.key}
            />
            {file.path || file.name}{' '}
            {file.size && ` - ${humanFileSize(file.size)} bytes`}
            {!file.loading && !isUploading && !disabled && (
              <FontAwesomeIcon
                icon={faTrash}
                onClick={() => {
                  setIsUploading(true);
                  let newValue = filter(
                    value,
                    item => item.Location !== file.Location
                  );
                  onSave(newValue).then(() => {
                    setIsUploading(false);
                  });
                }}
              />
            )}
          </li>
        ))}
      {invalid && (
        <InvalidFileMessage>
          File is too large, or incorrect type!
        </InvalidFileMessage>
      )}
    </FormGroup>
  );
}

function humanFileSize(size) {
  let i = Math.floor(Math.log(size) / Math.log(1024));
  return (
    (size / Math.pow(1024, i)).toFixed(2) * 1 +
    ' ' +
    ['B', 'kB', 'MB', 'GB', 'TB'][i]
  );
}

AttachmentQuestion.propTypes = propTypes;
AttachmentQuestion.defaultProps = defaultProps;

export default AttachmentQuestion;

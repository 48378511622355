import React from 'react';
import { questionShape } from 'utils/shapes';
import { getQuestionLocalization } from 'utils/questionnaireHelpers';

const propTypes = {
  question: questionShape.isRequired
};

const defaultProps = {};

function QuestionBody({ question }) {
  const localization = getQuestionLocalization(question);

  if (!localization || !localization.body) {
    return null;
  }

  return <div>{localization.body && <p>{localization.body}</p>}</div>;
}

QuestionBody.propTypes = propTypes;
QuestionBody.defaultProps = defaultProps;

export default QuestionBody;

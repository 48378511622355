import React from 'react';
import { Route } from 'react-router-dom';
import Applications from './Applications';
import Home from './Home';
import HelpSupport from '../../HelpSupportPage';

const basePath = '/admin';

const routes = [
  <Route key="admin-home" path={`${basePath}`} exact component={Home} />,
  <Route
    key="admin-applications"
    path={`${basePath}/applications`}
    exact
    component={Applications}
  />,
  <Route
    key="admin-help"
    path={`${basePath}/help`}
    exact
    component={HelpSupport}
  />
];

export default routes;

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Nav } from 'reactstrap';
import NavItem from 'reactstrap/es/NavItem';
import { StyledNavLink } from './index';
import styled from 'styled-components';

const propTypes = {};

const defaultProps = {};

const MenuTitle = styled.h3`
  text-align: center;
`;

function Sidebar({ history }) {
  return (
    <>
      <Nav vertical className="mb-5">
        <MenuTitle>Small Exchange</MenuTitle>
        <MenuTitle>Admin Panel</MenuTitle>
        <NavItem className="border-top border-bottom">
          <StyledNavLink
            tag={Link}
            to="/admin/applications"
            active={history.location.pathname === '/admin/applications'}
          >
            Applications
          </StyledNavLink>
        </NavItem>
      </Nav>
    </>
  );
}

Sidebar.propTypes = propTypes;
Sidebar.defaultProps = defaultProps;

export default withRouter(Sidebar);

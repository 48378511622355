import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { H1 } from 'components/Heading';
import ListGroup from 'reactstrap/es/ListGroup';
import ListGroupItem from 'reactstrap/es/ListGroupItem';
import { Badge, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import { faCommentAlt } from '@fortawesome/free-regular-svg-icons';
import styled from 'styled-components';
import CustomButton from 'components/Button';
import { useSelector } from 'react-redux';
import { getQuestionnaireResponse } from '../../selectors/questionnaireResponse';
import { useMutation, useRequest } from 'redux-query-react';
import {
  updateQuestionnaireResponseMutation,
  questionnaireResponsesRequest
} from '../../query-configs/questionnaireResponse';
import {
  QUESTIONNAIRE_UUID,
  STATUS_SUBMITTED as QUESTIONNAIRE_STATUS_SUBMITTED
} from 'utils/constants';

const propTypes = {};

const defaultProps = {};

const STATUS_FINAL_APPROVED = 'Final Approved';
const STATUS_IN_REVIEW = 'In Review';
const STATUS_SUBMITTED = 'Submitted';
const STATUS_NEEDS_RESPONSE = 'Needs Response';
const STATUS_NEEDS_SIGNATURE = 'Please Sign';
const STATUS_PENDING_OTHER_DOCS = 'Pending Other Docs';

const mockData = [
  {
    name: 'Small Exchange Member Agreement',
    status: STATUS_FINAL_APPROVED
  },
  {
    name: 'Market Data Agreement',
    status: STATUS_IN_REVIEW
  },
  {
    name: 'Privacy and Data Confidentiality Policy',
    status: STATUS_SUBMITTED
  },
  {
    name: 'Connection Agreement',
    status: STATUS_NEEDS_RESPONSE
  },
  {
    name: 'Risk Monitoring License and Services Agreement',
    status: STATUS_NEEDS_SIGNATURE
  },
  {
    name: 'Market Maker Agreement',
    status: STATUS_NEEDS_SIGNATURE
  },
  {
    name: 'Mutual Non-Disclosure',
    status: STATUS_NEEDS_SIGNATURE
  }
];

const mockDataMasters = [
  {
    name: 'Master Signature Document',
    status: STATUS_PENDING_OTHER_DOCS,
    isMaster: true
  }
];

const statusColors = {
  [STATUS_FINAL_APPROVED]: 'success',
  [STATUS_IN_REVIEW]: 'info',
  [STATUS_SUBMITTED]: 'info',
  [STATUS_NEEDS_RESPONSE]: 'warning',
  [STATUS_NEEDS_SIGNATURE]: 'primary',
  [STATUS_PENDING_OTHER_DOCS]: 'secondary'
};

const statusEditIcons = {
  [STATUS_FINAL_APPROVED]: faEdit,
  [STATUS_IN_REVIEW]: faEdit,
  [STATUS_SUBMITTED]: faEdit,
  [STATUS_NEEDS_RESPONSE]: faCommentAlt,
  [STATUS_NEEDS_SIGNATURE]: faEdit,
  [STATUS_PENDING_OTHER_DOCS]: faEdit
};

const StyledBadge = styled(Badge)`
  padding: 0.6rem;
  width: 10rem;
  font-size: 80%;
`;

const ActionItem = styled.span`
  width: 2.5rem;
  margin-left: 0.5rem;
`;

function AgreementItem(props) {
  return (
    <ListGroupItem className="d-flex justify-content-between align-items-center">
      {props.item.name}
      <div className="d-flex align-items-center">
        <StyledBadge pill color={statusColors[props.item.status]}>
          {props.item.status}
        </StyledBadge>
        <ActionItem>
          <Button
            block
            outline
            size="sm"
            color="dark"
            className="ml-2 border-0"
          >
            <FontAwesomeIcon size="lg" icon={faCloudDownloadAlt} />
          </Button>
        </ActionItem>
        <ActionItem>
          <Button block outline size="sm" color="dark" className="border-0">
            <FontAwesomeIcon
              size="lg"
              icon={statusEditIcons[props.item.status]}
            />
          </Button>
        </ActionItem>
      </div>
    </ListGroupItem>
  );
}

AgreementItem.propTypes = { item: PropTypes.any };

function AgreementsPage({ history }) {
  useRequest(questionnaireResponsesRequest(QUESTIONNAIRE_UUID));

  const questionnaireResponse = useSelector(getQuestionnaireResponse);

  // eslint-disable-next-line no-unused-vars
  const [_, makeQuestionnaireResponse] = useMutation(questionnaireResponse => {
    return updateQuestionnaireResponseMutation(questionnaireResponse);
  });

  return (
    <div>
      <Helmet>
        <title>Agreements and Disclosures</title>
      </Helmet>
      <React.StrictMode>
        <H1>Agreements and Disclosures</H1>
        <p>
          Fusce vehicula dolor arcu, sit amet blandit dolor mollis nec. Donec
          viverra eleifend lacus, vitae ullamcorper metus. Sed sollicitudin
          ipsum quis nunc sollicitudin ultrices. Donec euismod scelerisque
          ligula. Maecenas eu varius risus, eu aliquet arcu. Curabitur fermentum
          suscipit est, tincidunt mattis lorem luctus id.
        </p>
        <p>
          Donec eget massa a diam condimentum pretium. Aliquam erat volutpat.
          Integer ut tincidunt orci. Etiam tristique, elit ut consectetur
          iaculis, metus lectus mattis justo, vel mollis eros neque quis augue.
          Sed lobortis ultrices lacus, a placerat metus rutrum sit amet. Aenean
          ut suscipit justo.
        </p>

        <ListGroup className="mt-5">
          {mockData.map((item, i) => (
            <AgreementItem key={i} item={item} />
          ))}
        </ListGroup>

        <ListGroup className="my-5">
          {mockDataMasters.map((item, i) => (
            <AgreementItem key={i} item={item} />
          ))}
        </ListGroup>

        <div className="text-right">
          <CustomButton
            color="primary"
            size="lg"
            onClick={() => {
              const newState = Object.assign({}, questionnaireResponse, {
                status: QUESTIONNAIRE_STATUS_SUBMITTED
              });
              makeQuestionnaireResponse(newState)
                .then(() => {
                  history.push('/submit-confirmation');
                })
                .catch(console.error);
            }}
          >
            Submit My Application
          </CustomButton>
        </div>
      </React.StrictMode>
    </div>
  );
}

AgreementsPage.propTypes = propTypes;
AgreementsPage.defaultProps = defaultProps;

export default AgreementsPage;

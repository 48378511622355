import React from 'react';
import styled from 'styled-components';
import { Label } from 'reactstrap';
import { SAVING_COMPLETE, SAVING_STARTED } from '../index';
import { getQuestionLocalization } from 'utils/questionnaireHelpers';
import HelpTooltip from 'components/HelpTooltip';
import { isObject } from 'utils/functions';
import { useIsRequired } from 'hooks/useIsRequired';

const StyledLabel = styled(Label)`
  font-family: ${props => props.theme.fontFamilyCondensed};
  font-weight: ${props => props.theme.fontBold};
`;

function QuestionLabel({ question, children, saving, ...props }) {
  const localization = getQuestionLocalization(question);
  const mappedRequired = useIsRequired(question);

  const required = isObject(question.required)
    ? mappedRequired
    : question.required;

  return (
    <>
      <StyledLabel {...props}>
        {children}{' '}
        {required && (
          <span className="text-muted">
            *{' '}
            <small>
              <i>required</i>
            </small>
          </span>
        )}
        {saving === SAVING_STARTED && ` - Saving`}
        {saving === SAVING_COMPLETE && ` - Saved`}
      </StyledLabel>

      {localization && localization.instructions && (
        <HelpTooltip
          id={`help_${localization.uuid}`}
          header={children}
          className="ml-2"
        >
          {localization.instructions}
        </HelpTooltip>
      )}
    </>
  );
}

export default QuestionLabel;

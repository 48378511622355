import React, { Fragment, Component } from 'react';
import {
  Row,
  Col,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonGroup
} from 'reactstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Question from '../../index';
import CustomInput from 'reactstrap/es/CustomInput';
import { fromJS } from 'immutable';
import { findIndex, filter, find } from 'lodash';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import uuid from 'uuid';
import HelpTooltip from 'components/HelpTooltip';
import produce from 'immer';
import { validateRepresentativesAnswer } from 'utils/validators';

const StyledTable = styled(Table)`
  max-width: 58.125rem; /* 930px */

  thead {
    th {
      border-top: none;
      font-family: ${props => props.theme.fontFamilyCondensed};
    }
  }

  td {
    border-right: 1px solid #dee2e6;
    vertical-align: middle;

    &:last-of-type {
      border-right: none;
    }
  }
`;

const WrappedTableHeader = ({ className, children }) => (
  <th className={className}>
    <div>
      <span>{children}</span>
    </div>
  </th>
);

const RotatedTableHeader = styled(WrappedTableHeader)`
  // styled-components specificity workaround.
  && {
    /* Something you can count on */
    height: 200px;
    white-space: nowrap;
    vertical-align: middle;
  }

  & > div {
    transform:
      /* Magic Numbers */ translate(26px, 78px)
      /* 45 is really 360 - 45 */ rotate(-45deg);
    width: 30px;

    & > span {
      border-bottom: 1px solid #dee2e6;
      padding: 5px 10px;
    }
  }
`;

const RoleSelector = styled(Row)`
  display: flex;
  justify-content: center;
`;

const propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  question: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    branchMapSummary: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    localizations: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    ).isRequired,
    identifier: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired
  })
};

const defaultProps = {};

const defaultUser = {
  uuid: null,
  firstName: '',
  lastName: '',
  emailAddress: '',
  title: '',
  phoneNumber: ''
};

const traderFields = ['accountNumber', 'operatorId', 'automated'];

class RepresentativesMappedResponseQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      modal: defaultUser,
      validation: [],
      modalValidation: {}
    };
  }

  toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
      modal: defaultUser,
      modalValidation: {}
    });
  };

  submit = () => {
    const { modal: user } = this.state;

    const modalValidation = this.validateUser(user);

    if (modalValidation) {
      this.setState({ modalValidation });

      return false;
    } else {
      this.setState({ modalValidation: {} });
    }

    if (!user.uuid) {
      this.createUser(user);
    } else {
      this.editUser(user);
    }
  };

  validateUserField = (fieldName, value, isTrader = false) => {
    const {
      question: {
        responseTypeDef: { templateJSON }
      }
    } = this.props;

    const template = templateJSON.contactTemplate[fieldName];

    // Conditional trader field overrides.
    if (!isTrader && traderFields.includes(fieldName)) {
      return undefined;
    }

    if (template.required && !value) {
      return `Field is required`;
    }

    return undefined;
  };

  validateUser = user => {
    const {
      question: {
        responseTypeDef: { templateJSON }
      }
    } = this.props;

    const errors = {};

    const isTrader = user.role && user.role === 'Trader';

    Object.keys(templateJSON.contactTemplate).forEach(fieldName => {
      const result = this.validateUserField(
        fieldName,
        user[fieldName],
        isTrader
      );

      if (result) {
        errors[fieldName] = [result];
      }
    });

    return Object.keys(errors).length > 0 ? errors : undefined;
  };

  createUser = user => {
    const {
      question: {
        responseTypeDef: { templateJSON }
      },
      value
    } = this.props;

    const positions = Object.keys(templateJSON.contactMethods).map(method => {
      const { required } = templateJSON.contactMethods[method];

      return {
        questionUuid: method,
        required,
        checked: false
      };
    });

    const newUser = Object.assign({}, user, {
      positions,
      uuid: `u-${uuid()}`
    });

    this.setState({
      modal: defaultUser,
      modalOpen: false
    });

    this.saveData([...(value || []), newUser]);
  };

  editUser = user => {
    const map = fromJS(this.props.value || []);

    const newMap = map.setIn(
      [findIndex(this.props.value || [], u => user.uuid === u.uuid)],
      user
    );

    this.setState({
      modal: defaultUser,
      modalOpen: false
    });

    this.saveData(newMap.toJS());
  };

  onChange = (field, data) => {
    const isTrader =
      this.state.modal &&
      this.state.modal.role &&
      this.state.modal.role === 'Trader';

    return new Promise((resolve, reject) => {
      const validationResult = this.validateUserField(field, data, isTrader);

      const modalValidation = {
        ...this.state.modalValidation,
        [field]: validationResult ? [validationResult] : undefined
      };

      this.setState(
        {
          modal: { ...this.state.modal, [field]: data },
          modalValidation
        },
        () => resolve(this.state.modal)
      );
    });
  };

  onSelectPosition = (user, questionUuid, value) => {
    const positionIdx = findIndex(
      user.positions,
      p => p.questionUuid === questionUuid
    );

    const userIdx = findIndex(
      this.props.value || [],
      u => user.uuid === u.uuid
    );

    let pValue = [...this.props.value];

    if (positionIdx === -1) {
      pValue = produce(pValue, draftState => {
        draftState[userIdx].positions = [
          ...draftState[userIdx].positions,
          {
            questionUuid,
            checked: value
          }
        ];
      });
    }

    const map = fromJS(pValue || []);

    const newMap = map.setIn(
      [userIdx, 'positions', positionIdx, 'checked'],
      value
    );

    this.saveData(newMap.toJS());
  };

  onEditUser = user => {
    this.setState({
      modal: user,
      modalOpen: true
    });
  };

  onDeleteUser = user => {
    this.saveData(filter(this.props.value || [], u => u.uuid !== user.uuid));
  };

  userHasRole(user, role) {
    return !!find(
      user.positions,
      p => p.questionUuid === role && p.checked === true
    );
  }

  saveData(newValue) {
    const { onChange } = this.props;

    const {
      question: {
        responseTypeDef: {
          templateJSON: { contactMethods }
        }
      }
    } = this.props;

    const validation = validateRepresentativesAnswer(contactMethods, newValue);

    this.setState({
      validation
    });

    return onChange ? onChange(newValue) : Promise.resolve(newValue);
  }

  render() {
    const {
      question: {
        responseTypeDef: { templateJSON }
      },
      value,
      disabled
    } = this.props;

    const {
      modalOpen,
      modal: {
        firstName,
        lastName,
        emailAddress,
        title,
        uuid,
        phoneNumber,
        role,
        operatorId,
        accountNumber,
        automated
      },
      validation,
      modalValidation
    } = this.state;

    const showRole = templateJSON.contactTemplate.role;
    const isTrader = role === 'Trader';

    return (
      <Fragment>
        <StyledTable responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th>Title</th>
              {showRole && <th>Role</th>}
              {Object.keys(templateJSON.contactMethods).map(
                (methodUuid, key) => {
                  const { name, description } = templateJSON.contactMethods[
                    methodUuid
                  ];

                  return (
                    <RotatedTableHeader key={`methods_${methodUuid}_${key}`}>
                      {name}
                      {description && (
                        <HelpTooltip
                          id={`help_${methodUuid}`}
                          header={name}
                          iconSize="sm"
                          className="ml-2"
                        >
                          {description}
                        </HelpTooltip>
                      )}
                    </RotatedTableHeader>
                  );
                }
              )}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {value &&
              value.map((user, key) => {
                return (
                  <tr key={`user_rep_list_${key}`}>
                    <td>
                      {user.firstName} {user.lastName}
                    </td>
                    <td>{user.title}</td>

                    {showRole && <td>{user.role}</td>}
                    {Object.keys(templateJSON.contactMethods).map(method => {
                      const isRoleChecked = this.userHasRole(user, method);
                      const role = templateJSON.contactMethods[method];
                      let roleDisabled = false;
                      if (
                        role.disabledIf &&
                        role.disabledIf.length > 0 &&
                        !isRoleChecked
                      ) {
                        roleDisabled = !!role.disabledIf.find(v =>
                          this.userHasRole(user, v)
                        );
                      }

                      return (
                        <td
                          key={`user_rep_list_${user.uuid}_method_${method}`}
                          className="text-center"
                        >
                          <CustomInput
                            id={`user_${user.uuid}_role_${method}`}
                            type="checkbox"
                            onChange={e => {
                              this.onSelectPosition(
                                user,
                                method,
                                e.target.checked
                              );
                            }}
                            value={method}
                            checked={isRoleChecked}
                            disabled={disabled || roleDisabled}
                          />
                        </td>
                      );
                    })}
                    <td>
                      <ButtonGroup>
                        <Button
                          color="primary"
                          onClick={() => this.onEditUser(user)}
                          disabled={disabled}
                        >
                          Edit
                        </Button>
                        <Button
                          color="danger"
                          onClick={() => this.onDeleteUser(user)}
                          disabled={disabled}
                        >
                          Delete
                        </Button>
                      </ButtonGroup>
                    </td>
                  </tr>
                );
              })}
            <tr>
              <td>
                You'll need to pick a contact for each role. Contacts can have
                more than one role.
              </td>
              <td></td>
              {Object.keys(validation).map(v => (
                <td key={`info_validation_${v}`}>
                  {!validation[v] && (
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                  )}
                </td>
              ))}
              <td></td>
            </tr>
          </tbody>
        </StyledTable>
        <Button color="dark" disabled={disabled} onClick={this.toggleModal}>
          + Add Representative
        </Button>
        <Modal isOpen={modalOpen} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>
            Add a Representative to your Organization
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col sm={4}>
                <Question
                  question={templateJSON.contactTemplate.firstName}
                  onChange={data => this.onChange('firstName', data)}
                  value={firstName}
                  errors={modalValidation.firstName || []}
                />
              </Col>
              <Col sm={4}>
                <Question
                  question={templateJSON.contactTemplate.lastName}
                  onChange={data => this.onChange('lastName', data)}
                  value={lastName}
                  errors={modalValidation.lastName || []}
                />
              </Col>
              <Col sm={4}>
                <Question
                  question={templateJSON.contactTemplate.title}
                  onChange={data => this.onChange('title', data)}
                  value={title}
                  errors={modalValidation.title || []}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Question
                  question={templateJSON.contactTemplate.emailAddress}
                  onChange={data => this.onChange('emailAddress', data)}
                  value={emailAddress}
                  errors={modalValidation.emailAddress || []}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Question
                  question={templateJSON.contactTemplate.phoneNumber}
                  onChange={data => this.onChange('phoneNumber', data)}
                  value={phoneNumber}
                  errors={modalValidation.phoneNumber || []}
                />
              </Col>
            </Row>
            {templateJSON.contactTemplate.accountNumber &&
              templateJSON.contactTemplate.operatorId && (
                <>
                  <Row>
                    <Col sm={6}>
                      <Question
                        question={templateJSON.contactTemplate.role}
                        onChange={data => this.onChange('role', data)}
                        value={role}
                        errors={modalValidation.role || []}
                      />
                    </Col>
                  </Row>
                  {isTrader && (
                    <>
                      <Row>
                        <Col sm={6}>
                          <Question
                            question={
                              templateJSON.contactTemplate.accountNumber
                            }
                            onChange={data =>
                              this.onChange('accountNumber', data)
                            }
                            value={accountNumber}
                            errors={modalValidation.accountNumber || []}
                          />
                        </Col>
                        <Col sm={6}>
                          <Question
                            question={templateJSON.contactTemplate.operatorId}
                            onChange={data => this.onChange('operatorId', data)}
                            value={operatorId}
                            errors={modalValidation.operatorId || []}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Question
                            question={templateJSON.contactTemplate.automated}
                            onChange={data => this.onChange('automated', data)}
                            value={automated}
                            errors={modalValidation.automated || []}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.submit}>
              {uuid && 'Update'}
              {!uuid && 'Add'}
            </Button>{' '}
            <Button color="primary" onClick={this.toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

RepresentativesMappedResponseQuestion.propTypes = propTypes;
RepresentativesMappedResponseQuestion.defaultProps = defaultProps;

export default RepresentativesMappedResponseQuestion;

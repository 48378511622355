import React from 'react';
import PropTypes from 'prop-types';
import every from 'lodash/every';
import isNil from 'lodash/isNil';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import { sectionShape } from 'utils/shapes';
import SectionLink from 'components/SectionLink';
import Questions from 'routes/SectionPage/Questions';
import { Form, Row, Col } from 'reactstrap';
import { H1 } from 'components/Heading';
import { useSelector } from 'react-redux';
import { getAnswerValidationsBySection } from 'selectors/answer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import Button from 'components/Button';
import { getQuestionLocalization } from 'utils/questionnaireHelpers';
import { useMutation, useRequest } from 'redux-query-react';
import { getQuestionnaireResponse } from 'selectors/questionnaireResponse';
import { STATUS_SUBMITTED as QUESTIONNAIRE_STATUS_SUBMITTED } from 'utils/constants';
import {
  questionnaireResponsesRequest,
  updateQuestionnaireResponseMutation
} from 'query-configs/questionnaireResponse';

const propTypes = {
  section: sectionShape.isRequired,
  loadingAnswers: PropTypes.bool.isRequired,
  questionnaireId: PropTypes.string,
  history: PropTypes.any
};

const defaultProps = {};

function Section({ section, loadingAnswers, questionnaireId, history }) {
  const questionnaireResponse = useSelector(getQuestionnaireResponse);
  const questionnaireUuid = !isEmpty(questionnaireResponse)
    ? questionnaireResponse.questionnaireUuid
    : window.localStorage.getItem('questionnaireUuid');
  const answerValidations = flatten(
    flatten(
      Object.values(
        useSelector(
          getAnswerValidationsBySection(section.uuid, questionnaireUuid)
        )
      )
    ).map(v => {
      if (v && v.hasOwnProperty('key') && v.hasOwnProperty('value')) {
        return v.value;
      }
      return v;
    })
  );

  useRequest(
    questionnaireResponsesRequest(questionnaireUuid, questionnaireId, true)
  );

  // eslint-disable-next-line no-unused-vars
  const [_, updateQuestionnaireResponse] = useMutation(
    questionnaireResponse => {
      return updateQuestionnaireResponseMutation(questionnaireResponse);
    }
  );

  const isSectionComplete = every(answerValidations, isNil);

  const localization = getQuestionLocalization(section);

  return (
    <Row>
      <Col md={12} lg={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }}>
        <H1>{section.name}</H1>
        {localization && localization.body && (
          <p className="mb-5">{localization.body}</p>
        )}
        <Form>
          <Questions
            questions={section.questions.map(element => {
              return {
                questionId: element[0],
                responseId: element[1]
              };
            })}
          />
          <div className="text-right my-5">
            {section.nextSection ? (
              <SectionLink
                uuid={section.nextSection}
                disabled={!isSectionComplete}
                questionnaireId={
                  questionnaireResponse && questionnaireResponse._id
                }
                questionnaireUuid={questionnaireUuid}
              />
            ) : (
              <Button
                color="primary"
                size="lg"
                // to="/agreements"
                onClick={() => {
                  const newState = Object.assign({}, questionnaireResponse, {
                    status: QUESTIONNAIRE_STATUS_SUBMITTED
                  });
                  updateQuestionnaireResponse(newState)
                    .then(() => {
                      history.push('/submit-confirmation');
                    })
                    .catch(console.error);
                }}
                className="d-inline-flex justify-content-between align-items-center"
                disabled={!isSectionComplete}
              >
                {/*NEXT: Agreements and Disclosures*/}
                Submit My Application
                <FontAwesomeIcon
                  className="ml-4"
                  size="2x"
                  icon={faCaretRight}
                />
              </Button>
            )}
          </div>
        </Form>
      </Col>
    </Row>
  );
}

Section.propTypes = propTypes;
Section.defaultProps = defaultProps;

export default Section;

import React, { Fragment, useCallback } from 'react';
import { Row, Col, Button } from 'reactstrap';
import AddressQuestion from './AddressQuestion';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import filter from 'lodash/filter';
import omit from 'lodash/omit';
import { FormGroup, FormFeedback } from 'reactstrap';
import InputGroup from 'reactstrap/es/InputGroup';
import styled from 'styled-components';

const propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  question: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    branchMapSummary: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    localizations: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    ).isRequired,
    identifier: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired
  })
};

const StyledFormFeedback = styled(FormFeedback)`
  display: block;
`;

const defaultProps = {};

function AddressMappedResponseQuestion({
  question: {
    responseTypeDef: {
      multiple,
      templateJSON: { questions }
    }
  },
  value,
  onChange,
  meta: { errors }
}) {
  const onChangeCallback = useCallback(
    data => {
      return onChange ? onChange(data) : Promise.resolve(data);
    },
    [onChange]
  );

  const genericErrors = filter(
    errors,
    e => e instanceof String || typeof e === 'string'
  );
  const mappedErrors = filter(
    errors,
    e => !(e instanceof String || typeof e === 'string')
  );

  let addresses;

  if (Array.isArray(value)) {
    addresses = value;
  } else {
    // Backwards compatibility for old data structure.
    addresses = [];

    if (value && value.primaryAddress) {
      addresses.push(value.primaryAddress);
    }
    if (value && value.billingAddress) {
      addresses.push(value.billingAddress);
    }
  }

  if (addresses.length === 0) {
    // Add default address value.
    addresses.push({
      uuid: `r-${uuid()}`,
      addressPurpose: Object.keys(
        questions.addressPurpose.responseTypeDef.branchKeys
      )[0]
    });
  }

  // Backwards compat: Make sure each address has a uuid.
  addresses = addresses.map(address => {
    return address.uuid ? address : { ...address, uuid: `r-${uuid()}` };
  });

  return (
    <Fragment>
      {genericErrors &&
        genericErrors.map(e => (
          <StyledFormFeedback key={e}>{e}</StyledFormFeedback>
        ))}
      {addresses.map((addressValue, i) => {
        return (
          <Row key={addressValue.uuid}>
            <Col sm={12}>
              <AddressQuestion
                question={questions}
                value={addressValue}
                onChange={data => {
                  const newValue = addresses.map((item, index) => {
                    if (index !== i) {
                      return item;
                    }

                    return {
                      ...item,
                      ...data
                    };
                  });

                  return onChangeCallback(newValue);
                }}
                meta={{
                  touched: true,
                  errors: filter(
                    mappedErrors,
                    mE => mE.uuid === addressValue.uuid
                  )
                }}
              />
              {i > 0 && (
                <div className="text-right">
                  <Button
                    color="danger"
                    onClick={() => {
                      const newValue = [
                        ...addresses.slice(0, i),
                        ...addresses.slice(i + 1)
                      ];
                      return onChangeCallback(newValue);
                    }}
                  >
                    - Remove Address
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        );
      })}
      {multiple && (
        <Row>
          <Col sm={12}>
            <Button
              color="primary"
              onClick={() =>
                onChangeCallback([
                  ...addresses,
                  {
                    uuid: `r-${uuid()}`
                  }
                ])
              }
            >
              + Add Address
            </Button>
          </Col>
        </Row>
      )}
    </Fragment>
  );
}

AddressMappedResponseQuestion.propTypes = propTypes;
AddressMappedResponseQuestion.defaultProps = defaultProps;

export default AddressMappedResponseQuestion;

import keyBy from 'lodash/keyBy';
import filter from 'lodash/filter';
import find from 'lodash/find';
import mapValues from 'lodash/mapValues';
import { validateAnswer } from 'utils/validators';
import {
  getAllResponseIdsWithQuestions,
  getSectionByUuid,
  getSections
} from 'utils/questionnaireHelpers';
import every from 'lodash/every';
import isNil from 'lodash/isNil';
import {
  STATUS_COMPLETED,
  STATUS_NOT_STARTED,
  STATUS_STARTED
} from 'utils/constants';

export const getAnswers = state => state.entities.answersByResponseUuid;
export const getAllAnswers = () => state =>
  state.entities.answersByResponseUuid;

export const getAnswerByResponseUuid = responseUuid => state => {
  const { answersByResponseUuid } = state.entities;

  return responseUuid && answersByResponseUuid
    ? answersByResponseUuid[responseUuid]
    : null;
};

export const getAnswerByQuestionUuid = questionUuid => state => {
  const { answersByResponseUuid } = state.entities;

  return find(
    answersByResponseUuid,
    element => element.questionUuid === questionUuid
  );
};

export const getAnswerValidations = state => {
  const answers = getAnswers(state);

  if (!answers) {
    return {};
  }

  const responseIds = getAllResponseIdsWithQuestions();

  return mapValues(responseIds, (question, responseId) => {
    const answer = answers[responseId];

    return validateAnswer(answer, question, answers);
  });
};

export const getAnswerValidationsBySection = (
  sectionUuid,
  questionnaireUuid
) => state => {
  const section = getSectionByUuid(sectionUuid, questionnaireUuid);
  if (!section) {
    throw new Error(`Section with uuid ${sectionUuid} not found.`);
  }

  const answerValidations = getAnswerValidations(state);

  return section.questions.reduce((result, [questionId, responseId]) => {
    result[responseId] = answerValidations[responseId];
    return result;
  }, {});
};

export const selectSectionStatuses = questionnaireUuid => state => {
  const sections = getSections(questionnaireUuid);
  const answers = getAnswers(state);

  if (!answers) {
    return undefined;
  }

  let firstIncompleteSectionVisited = false;

  return mapValues(keyBy(sections, 'uuid'), section => {
    const answerValidations = getAnswerValidationsBySection(
      section.uuid,
      questionnaireUuid
    )(state);

    const isSectionComplete = every(answerValidations, isNil);

    if (isSectionComplete) {
      return STATUS_COMPLETED;
    }

    // Default the first section that is incomplete to "started".
    if (false === firstIncompleteSectionVisited) {
      firstIncompleteSectionVisited = true;
      return STATUS_STARTED;
    }

    const filteredValidations = filter(
      answerValidations,
      (validation, responseId) => !!answers[responseId]
    );

    // If any answers exist for this section, we'll say it is started. Otherwise, not started.
    const isStarted = !!find(filteredValidations, isNil);

    return isStarted ? STATUS_STARTED : STATUS_NOT_STARTED;
  });
};

import React from 'react';
import PropTypes from 'prop-types';
import { questionShape } from 'utils/shapes';
import { FormGroup } from 'reactstrap';
import QuestionBody from './QuestionBody';
import AddressMappedResponseQuestion from './MappedResponses/AddressMappedResponseQuestion';
import ContactMappedResponseQuestion from './MappedResponses/ContactMappedResponseQuestion';
import SelectQuestion from './SelectQuestion';
import NotFound from '../../../routes/NotFound';
import RepresentativesMappedResponseQuestion from './MappedResponses/RepresentativesMappedResponseQuestion';
import QuestionLabel from './QuestionLabel';
import { MAPPED_RESPONSE_TYPES } from 'utils/constants';
import CountrySelectQuestion from './MappedResponses/CountrySelectQuestion';

const MAPPED_RESPONSES = {
  [MAPPED_RESPONSE_TYPES.address]: AddressMappedResponseQuestion,
  [MAPPED_RESPONSE_TYPES.select]: SelectQuestion,
  [MAPPED_RESPONSE_TYPES.contact]: ContactMappedResponseQuestion,
  [MAPPED_RESPONSE_TYPES.representatives]: RepresentativesMappedResponseQuestion,
  [MAPPED_RESPONSE_TYPES.countrySelect]: CountrySelectQuestion
};

const propTypes = {
  question: questionShape.isRequired,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  value: PropTypes.any,
  hideSummary: PropTypes.bool,
  disabled: PropTypes.bool,
  saving: PropTypes.string
};

const defaultProps = {};

function MappedResponseQuestion({
  question,
  onChange,
  onSave,
  value,
  hideSummary,
  disabled,
  saving,
  meta
}) {
  const FieldQuestion =
    MAPPED_RESPONSES[question.responseTypeDef.componentType];

  if (!FieldQuestion) {
    return <NotFound />;
  }

  return (
    <FormGroup>
      <QuestionBody question={question} />
      {!hideSummary && (
        <QuestionLabel question={question} for={question.uuid} saving={saving}>
          {question.summary}
        </QuestionLabel>
      )}
      <FieldQuestion
        meta={meta}
        question={question}
        disabled={disabled}
        value={value}
        onChange={data => {
          onChange && onChange(data);
          return onSave ? onSave(data) : Promise.resolve(data);
        }}
      />
    </FormGroup>
  );
}

MappedResponseQuestion.propTypes = propTypes;
MappedResponseQuestion.defaultProps = defaultProps;

export default MappedResponseQuestion;

import { makeResourceUrl } from 'query-configs/answer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import React from 'react';
import { getToken } from 'utils/auth';

const propTypes = {
  questionnaireResponseId: PropTypes.string.isRequired,
  answerId: PropTypes.string.isRequired,
  fileKey: PropTypes.string.isRequired
};

function AttachmentQuestionDownload({
  questionnaireResponseId,
  answerId,
  fileKey
}) {
  const path =
    makeResourceUrl(questionnaireResponseId) +
    `/${answerId}/file/${btoa(fileKey)}?bearerToken=${getToken()}`;

  return (
    <a href={path} target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={faDownload} />
    </a>
  );
}

AttachmentQuestionDownload.propTypes = propTypes;

export default AttachmentQuestionDownload;

import React, { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { questionShape } from 'utils/shapes';
import TextQuestion from './components/TextQuestion';
import EnumQuestion from './components/EnumQuestion';
import MappedResponseQuestion from './components/MappedResponseQuestion';
import AttachmentQuestion from './components/AttachmentQuestion';
import { useSelector } from 'react-redux';
import { getQuestionnaireResponse } from 'selectors/questionnaireResponse';
import { getAnswerByResponseUuid } from 'selectors/answer';
import { useMutation } from 'redux-query-react';
import {
  createAnswerMutation,
  updateAnswerMutation
} from 'query-configs/answer';
import { getQuestionnaireVersion } from 'utils/questionnaireHelpers';
import {
  TYPE_ATTACHMENT,
  TYPE_ENUM,
  TYPE_MAPPED_RESPONSE,
  TYPE_TEXT
} from 'utils/constants';
import { isExchangeAdmin } from 'utils/auth';
import { validateAnswer } from 'utils/validators';
import { getAnswers } from '../../selectors/answer';

const componentTypes = {
  [TYPE_TEXT]: TextQuestion,
  [TYPE_ENUM]: EnumQuestion,
  [TYPE_ATTACHMENT]: AttachmentQuestion,
  [TYPE_MAPPED_RESPONSE]: MappedResponseQuestion
};

const propTypes = {
  question: questionShape.isRequired,
  hideSummary: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  responseId: PropTypes.string
};

const defaultProps = {};

export const SAVING_NONE = 'none';
export const SAVING_STARTED = 'saving_started';
export const SAVING_COMPLETE = 'saving_complete';

function Question({
  question,
  hideSummary,
  disabled,
  value,
  onChange,
  onSave,
  responseId,
  errors = []
}) {
  const questionnaireResponse = useSelector(getQuestionnaireResponse);
  const answer = useSelector(getAnswerByResponseUuid(responseId));
  const answers = useSelector(getAnswers);
  const [responseData, setResponseData] = useState(
    value || (answer && answer.responseData)
  );
  const [saving, setSaving] = useState('none');

  const [meta, setMeta] = useState({ touched: false, errors: [] });

  let savingTimeoutRef = useRef(null);

  // Updates saving flag to hide it after 2.5 seconds
  useEffect(() => {
    if (saving === SAVING_COMPLETE) {
      if (savingTimeoutRef.current) {
        clearTimeout(savingTimeoutRef.current);
      }

      savingTimeoutRef.current = setTimeout(() => {
        setSaving(SAVING_NONE);
        savingTimeoutRef.current = null;
      }, 2500);
    }
  }, [saving]);

  // Update responseData state if props change.
  useEffect(() => {
    const newValue = value || (answer && answer.responseData);

    setResponseData(newValue);
  }, [answer, value]);

  // eslint-disable-next-line no-unused-vars
  const [answerResponse, saveAnswer] = useMutation(answer =>
    answer._id
      ? updateAnswerMutation(questionnaireResponse._id, answer)
      : createAnswerMutation(questionnaireResponse._id, answer)
  );

  const onChangeCallback = useCallback(
    data => {
      setResponseData(data);
      return onChange ? onChange(data) : Promise.resolve(data);
    },
    [onChange, setResponseData]
  );

  /**
   * Mapped Responses use different validation systems to place errors correctly on the question fields
   *
   * @type {Function}
   */
  const saveAnswerCallback = useCallback(
    data => {
      if (onSave) {
        onSave(data);
      }

      if (responseId) {
        setSaving(SAVING_STARTED);
        const newAnswer = Object.assign({}, answer || {}, {
          responseUuid: responseId,
          questionUuid: question.uuid,
          questionVersion: question.version,
          questionnaireVersion: getQuestionnaireVersion(
            questionnaireResponse.questionnaireUuid
          ),
          responseData: data
        });

        const validation = validateAnswer(newAnswer, question, answers);

        const newState = {
          ...meta,
          touched: true,
          ...(validation && validation.length
            ? { errors: [...validation] }
            : { errors: [] })
        };

        setMeta(newState);

        return saveAnswer(newAnswer).then(() => {
          setSaving(SAVING_COMPLETE);
        });
      }
    },
    [onSave, responseId, answer, question, saveAnswer, meta, setMeta]
  );

  const FieldComponent = componentTypes[question.responseTypeDef.responseType];

  if (!FieldComponent) {
    console.log(
      'field component not found: ',
      question.responseTypeDef.responseType,
      componentTypes
    );

    throw new Error(
      `No component type mapping found for question with response type ${question.responseTypeDef.responseType}`
    );
  }

  return (
    <FieldComponent
      saving={saving}
      answer={answer}
      hideSummary={hideSummary || question.hideSummary}
      meta={{
        ...meta,
        touched: errors ? true : meta.touched,
        errors: [...meta.errors, ...errors]
      }}
      disabled={isExchangeAdmin() || disabled}
      value={responseData}
      question={question}
      onChange={onChangeCallback}
      onSave={saveAnswerCallback}
    />
  );
}

Question.propTypes = propTypes;
Question.defaultProps = defaultProps;

export default Question;

import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';

const Title = styled.h1`
  color: red;
`;

const SubTitle = styled.h2`
  color: darkred;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

function NotFound() {
  return (
    <Container>
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
      <Title>404</Title>
      <SubTitle>Page Not Found</SubTitle>
    </Container>
  );
}

export default NotFound;

export const API_URL = process.env.REACT_APP_API_URL;

export const QUESTIONNAIRE_UUID = process.env.REACT_APP_QUESTIONNAIRE_UUID;

export const TYPE_TEXT = 'text';
export const TYPE_ENUM = 'enum';
export const TYPE_ATTACHMENT = 'attachment';
export const TYPE_MAPPED_RESPONSE = 'map';

export const CONSTRAINT_TYPE_LENGTH = 'length';
export const CONSTRAINT_TYPE_ALPHANUMERIC = 'alphanumeric';
export const CONSTRAINT_TYPE_NUMERIC = 'numeric';
export const CONSTRAINT_TYPE_REGEX = 'regex';
export const CONSTRAINT_TYPE_EMAIL = 'email';
export const CONSTRAINT_TYPE_REQUIRED = 'required';

export const REGEX_ALPHANUMERIC = /^[a-zA-Z\d]+$/;
export const REGEX_NUMERIC = /^[\d]+$/;
export const REGEX_EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;

export const STATUS_NOT_STARTED = 'not_started';
export const STATUS_STARTED = 'started';
export const STATUS_COMPLETED = 'completed';

export const STATUS_UNSUBMITTED = 'unsubmitted';
export const STATUS_SUBMITTED = 'submitted';
export const STATUS_DENIED = 'denied';
export const STATUS_NEEDS_ATTENTION = 'needs_attention';
export const STATUS_APPROVED = 'approved';

export const MAPPED_RESPONSE_TYPES = {
  address: 'address',
  select: 'select',
  contact: 'contact',
  representatives: 'representatives',
  countrySelect: 'countrySelect'
};

export const QUESTIONNAIRE_RESPONSE_DOCUMENT_TYPES = {
  answersCSV: 'answers_csv',
  answersPDF: 'answersPDF'
};

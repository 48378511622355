import React from 'react';
import { H2, H3 } from 'components/Heading';

function Overview() {
  return (
    <>
      <section>
        <p>
          Please take a moment to review the steps below that outline the
          application process and describe the information you will need to
          provide during the process. Please reach out to Natalie Oblazny at{' '}
          <a href="mailto:natalie.oblazny@smallexchange.com">
            natalie.oblazny@smallexchange.com
          </a>{' '}
          if you have any questions or need assistance.
        </p>
      </section>
      <H2>Organization Information</H2>
      <section>
        <H3>Membership Type</H3>
        <p>
          In this section, you will specify the Membership type you are applying
          for (Clearing or non-Clearing) and will also have the option to
          indicate if you will be acting as a Market Maker for the Exchange.
        </p>
      </section>
      <section>
        <H3>Organization Contact Information</H3>
        <p>
          You are required to provide your organization’s legal address and will
          have the option to add additional addresses (e.g. billing, mailing,
          etc.) if such addresses are different from the legal address. Next,
          you will provide a general phone number, email address and website for
          your organization. Later in the application you will be asked to
          provide this type of information for specific individuals.
        </p>
      </section>
      <section>
        <H3>Organization Identifiers</H3>
        <p>
          Please select your type of organization and provide the applicable
          information. If your organization is registered in the U.S., you are
          required to provide your tax ID. If your organization is registered
          outside the U.S., you are required to provide your Legal Entity
          Identifier (LEI). Please enter any other identifiers associated with
          your organization.
        </p>
      </section>
      <section>
        <H3>Provide Organization Representatives</H3>
        <p>
          You must appoint representatives for the following functions:
          operations, compliance, technology, finance, risk, and emergency. One
          person can act as a representative for multiple roles, with the
          exception of the emergency contact. The individuals you include
          appoint for each function will be authorized to give related
          instructions to the Exchange on behalf of the organization and the
          Exchange may act upon that instruction. Two (2) account administrators
          are required (a primary and backup) who shall represent and act for
          the Member in all the affairs of the Exchange. The administrators
          shall be authorized to act on behalf of the Member. Each administrator
          shall maintain an email account for communication with the Small
          Exchange and shall update contact information as prescribed by the
          Small Exchange. Please see the Membership Agreement for a full list of
          the administrator's responsibilities. You can also list all persons
          who are authorized to interact with the Exchange via phone or email as
          representatives. You do not have to list all authorized
          representatives at this time and will be able to add them at a later
          date.
        </p>
      </section>
      <section>
        <H3>Organization Compliance History</H3>
        <p>
          Please respond to the compliance and risk questions. If you respond
          yes to any of the items, you will be required to provide additional
          detail and you will have the option to add supporting documents.
        </p>
      </section>
      <section>
        <H3>Documents to Be Submitted</H3>
        <p>You will be required to upload the following documents:</p>
        <ul>
          <li>
            Business Continuity Disclosure &mdash; please submit a summary of
            your BCP Plan. We do not require a full copy of your Plan.
          </li>
          <li>Anti-Money Laundering Policy</li>
          <li>
            Organization Formation Documents &mdash; please attach the Corporate
            or Entity Formation Documentation, Articles of Incorporation and
            other relevant documents.
          </li>
          <li>
            Notarized Certificate of Incumbency &mdash; please provide the
            document stating the individuals authorized to sign and enact in
            legally binding transactions on behalf of your organization.
            <ul>
              <li>
                We will also require government issued identifications for all
                individuals listed on this document.
              </li>
            </ul>
          </li>
          <li>Previous year’s audited financial statement</li>
          <li>Current year’s unaudited financial statement</li>
        </ul>
      </section>
      <section>
        <H3>Agreements & Disclosures</H3>
        <p>
          The required agreements and disclosures will be emailed to the
          authorized signer’s email address within 24 hours of the information
          being submitted. These will be sent from an @docusign.net email
          address and will need to be signed electronically.
        </p>
      </section>
    </>
  );
}

export default Overview;

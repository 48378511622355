import React from 'react';
import Helmet from 'react-helmet';
import { H1 } from 'components/Heading';
import CustomButton from 'components/Button';

const propTypes = {};

const defaultProps = {};

function SubmitConfirmationPage({ history }) {
  return (
    <div>
      <Helmet>
        <title>Submission Confirmation</title>
      </Helmet>
      <React.StrictMode>
        <H1>Your Application Has Been Submitted</H1>
        <p>
          A representative will be in touch soon with next steps. If you need
          immediate assistance, please contact{' '}
          <a
            href="mailto:support@thesmallexchange.com"
            rel="noreferrer noopener"
            target="_blank"
          >
            support@thesmallexchange.com
          </a>
        </p>

        <div className="my-5">
          <CustomButton
            color="primary"
            size="lg"
            onClick={() => history.push('/')}
          >
            Return to Application Overview
          </CustomButton>
        </div>
      </React.StrictMode>
    </div>
  );
}

SubmitConfirmationPage.propTypes = propTypes;
SubmitConfirmationPage.defaultProps = defaultProps;

export default SubmitConfirmationPage;

export const getQuestionnaireResponses = state =>
  state.entities.questionnaireResponsesById;

export const getQuestionnaireResponse = state => {
  const { questionnaireResponse, questionnaireResponsesById } = state.entities;

  return questionnaireResponse
    ? questionnaireResponsesById[questionnaireResponse]
    : null;
};

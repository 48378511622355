import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import {
  findSectionByCategoryAndSlug,
  getSections
} from 'utils/questionnaireHelpers';
import NotFound from 'routes/NotFound';
import Section from './Section';
import { useSelector } from 'react-redux';
import { getQuestionnaireResponse } from 'selectors/questionnaireResponse';
import { useRequest } from 'redux-query-react';
import { questionnaireResponsesRequest } from 'query-configs/questionnaireResponse';
import { answersRequest } from 'query-configs/answer';
import { Redirect } from 'react-router-dom';
import { isExchangeAdmin } from '../../utils/auth';
import AdminMenu from './AdminMenu';
import isEmpty from 'lodash/isEmpty';

function SectionPage({ match: { params }, history }) {
  const { category, slug, questionnaireId } = params;

  const localQuestionnaireUuid = window.localStorage.getItem(
    'questionnaireUuid'
  );

  const [{ isFinished: responsesRequestFinished }] = useRequest(
    questionnaireResponsesRequest(null, questionnaireId, true)
  );
  const questionnaireResponse = useSelector(getQuestionnaireResponse);

  const questionnaireUuid = !isEmpty(questionnaireResponse)
    ? questionnaireResponse.questionnaireUuid
    : localQuestionnaireUuid;

  const [{ isPending: answersRequestIsPending }] = useRequest(
    answersRequest(questionnaireResponse && questionnaireResponse._id)
  );

  let section;
  if (questionnaireUuid) {
    section = findSectionByCategoryAndSlug(category, slug, questionnaireUuid);
    if (!category && !slug && questionnaireId) {
      const section = getSections(questionnaireUuid)[0];
      return (
        <Redirect
          to={`/application/${questionnaireId}/section/${section.categorySlug}/${section.slug}`}
        />
      );
    }
  }

  if (!section) {
    return <NotFound />;
  }
  // If no questionnaire response exists, redirect back to the home page.
  if (responsesRequestFinished && !questionnaireResponse) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <Helmet>
        <title>{section.name}</title>
      </Helmet>
      <React.StrictMode>
        {isExchangeAdmin() && <AdminMenu />}
        <Section
          section={section}
          loadingAnswers={answersRequestIsPending}
          questionnaireId={questionnaireId}
          history={history}
        />
      </React.StrictMode>
    </div>
  );
}

export default SectionPage;

export const buildURLQuery = obj =>
  Object.entries(obj)
    .map(pair => pair.map(encodeURIComponent).join('='))
    .join('&');

export const buildUrl = (path, routeParams, queryParams) => {
  let url = `${process.env.REACT_APP_API_URL}${path}`;

  Object.keys(routeParams).forEach(key => {
    url = url.replace(`:${key}`, routeParams[key]);
  });

  return `${url}?${buildURLQuery(queryParams)}`;
};

export const isObject = value => {
  return value && typeof value === 'object' && value.constructor === Object;
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { questionShape } from 'utils/shapes';
import { getQuestion } from 'utils/questionnaireHelpers';
import { Alert } from 'reactstrap';
import Question from 'components/Question/index';

const Wrapper = styled.div`
  padding-left: 1rem;
`;

const propTypes = {
  question: questionShape,
  value: PropTypes.any
};

const defaultProps = {};

function BranchMap({ question, value }) {
  const { branchMap } = question;

  if (!branchMap || branchMap.length === 0) {
    return null;
  }

  const activeBranches = branchMap.filter(branch => branch.key === value);

  if (activeBranches.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      {activeBranches.map(branch => {
        const branchQuestion = getQuestion(branch.questionUuid);

        if (!branchQuestion) {
          return (
            <Alert color="danger">
              Missing question definition: {branch.questionUuid}
            </Alert>
          );
        }

        return (
          <Question
            question={branchQuestion}
            key={`${branch.questionUuid}/${branch.responseUuid}`}
            responseId={branch.responseUuid}
          />
        );
      })}
    </Wrapper>
  );
}

BranchMap.propTypes = propTypes;
BranchMap.defaultProps = defaultProps;

export default BranchMap;

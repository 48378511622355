import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Alert, Container, Row } from 'reactstrap';
import { Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import routes from './routes';
import Sidebar from 'components/Sidebar';
import {
  getQuestionnaire,
  getQuestionnaireByUuid
} from 'utils/questionnaireHelpers';
import { getQuestionnaireResponse } from 'selectors/questionnaireResponse';
import GlobalStyle from './GlobalStyle';
import keycloak from 'utils/keycloak';
import { getKeycloakValues, isAuthenticated, refreshToken } from 'utils/auth';
import ErrorBoundary from './ErrorBoundary';
import isEmpty from 'lodash/isEmpty';

const Main = styled.main.attrs(props => ({
  className: 'col-md-9 ml-sm-auto col-lg-10',
  role: 'main'
}))`
  padding: 3rem 1.5rem;
`;

function App() {
  const [isAuthCheckComplete, setIsAuthCheckComplete] = useState(false);
  const [authError, setAuthError] = useState(false);
  const questionnaireResponse = useSelector(getQuestionnaireResponse);
  const questionnaireUuid = !isEmpty(questionnaireResponse)
    ? questionnaireResponse.questionnaireUuid
    : window.localStorage.getItem('questionnaireUuid');
  useEffect(() => {
    keycloak
      .init({
        onLoad: 'check-sso',
        checkLoginIframe: false,
        ...getKeycloakValues()
      })
      .success(() => {
        if (isAuthenticated()) {
          refreshToken()
            .then(() => {
              setIsAuthCheckComplete(true);
              setAuthError(false);
            })
            .catch(() => {
              setIsAuthCheckComplete(true);
              setAuthError(true);
            });
        } else {
          setIsAuthCheckComplete(true);
          setAuthError(false);
        }
      })
      .error(e => {
        setIsAuthCheckComplete(true);
        setAuthError(true);
      });
  }, []);

  if (!isAuthCheckComplete) {
    return null;
  }

  if (authError) {
    return (
      <Alert color="danger">
        An error occurred during the log in process. You can refresh to try
        again - if the issue persists you may need to{' '}
        <a href={keycloak.createLogoutUrl()}>logout</a> and sign back in.
      </Alert>
    );
  }

  return (
    <ErrorBoundary>
      <Helmet titleTemplate="%s | Small Exchange Membership">
        <title>Membership Application</title>
      </Helmet>
      <GlobalStyle />
      <Container fluid>
        <Row>
          <React.StrictMode>
            <Sidebar
              questionnaire={getQuestionnaireByUuid(questionnaireUuid)}
            />
          </React.StrictMode>
          <Main>
            <Container fluid>
              <Switch>{routes}</Switch>
            </Container>
          </Main>
        </Row>
      </Container>
    </ErrorBoundary>
  );
}

export default App;

import styled from 'styled-components';
import { Button as BaseButton } from 'reactstrap';

const Button = styled(BaseButton)`
  font-weight: ${props => props.theme.fontBold};
  font-size: 1rem;
  padding: 0.75rem;
`;

export default Button;

import React from 'react';
import PropTypes from 'prop-types';
import largeSpinner from './Spinner-1s-200px.svg';
import smallSpinner from './Spinner-1s-50px.svg';

const propTypes = {
  small: PropTypes.bool
};

const defaultProps = {
  small: false
};

function LoadingIndicator({ small }) {
  return <img src={small ? smallSpinner : largeSpinner} alt="loading..." />;
}

LoadingIndicator.propTypes = propTypes;
LoadingIndicator.defaultProps = defaultProps;

export default LoadingIndicator;

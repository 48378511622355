import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { isAuthenticated } from 'utils/auth';
import { Redirect } from 'react-router-dom';

const Title = styled.h1`
  color: red;
`;

const SubTitle = styled.h2`
  color: darkred;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

function UnauthorizedPage() {
  if (!isAuthenticated()) {
    return <Redirect to="/login" />;
  }

  return (
    <Container>
      <Helmet>
        <title>Access Denied</title>
      </Helmet>
      <Title>Access Denied</Title>
      <SubTitle>You are not permitted to access this page.</SubTitle>
    </Container>
  );
}

export default UnauthorizedPage;

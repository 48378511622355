import { API_URL } from 'utils/constants';
import { getToken } from 'utils/auth';

export const RESOURCE_URL =
  API_URL + '/api/questionnaire-responses/:questionnaireResponseId/documents';

// Query config for retrieving all questionnaire response documents
export const questionnaireResponseDocumentsRequest = (
  questionnaireResponseId,
  force = false
) => ({
  url: `${RESOURCE_URL}`.replace(
    ':questionnaireResponseId',
    questionnaireResponseId
  ),
  options: {
    headers: {
      'x-auth': getToken()
    }
  },
  force,
  transform: body => {
    return {
      questionnaireResponseDocuments: body
    };
  },
  update: {
    questionnaireResponseDocuments: (oldValue, newValue) => newValue
  }
});

import React from 'react';
import styled from 'styled-components';
import { Nav, NavbarBrand, NavLink } from 'reactstrap';
import groupBy from 'lodash/groupBy';
import every from 'lodash/every';
import { Link, withRouter } from 'react-router-dom';
import { questionnaireShape, sidebarQuestionnaireShape } from 'utils/shapes';
import { useSelector } from 'react-redux';
import { selectSectionStatuses } from 'selectors/answer';
import { STATUS_COMPLETED } from 'utils/constants';
import { isAuthenticated, isExchangeAdmin } from 'utils/auth';
import NavItem from 'reactstrap/es/NavItem';
import { getQuestionnaireResponse } from 'selectors/questionnaireResponse';
import { Route, Switch } from 'react-router-dom';
import UserSidebar from './UserSidebar';
import AdminSidebar from './AdminSidebar';
import logo from './smLogo.svg';

const propTypes = {
  questionnaire: sidebarQuestionnaireShape
};

const defaultProps = {};

const SidebarWrapper = styled.nav.attrs(props => ({
  className: 'col-md-3 d-none d-md-block col-lg-2'
}))`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.colors.gray};
`;

const StickySidebar = styled.div`
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */

  @supports ((position: -webkit-sticky) or (position: sticky)) {
    position: -webkit-sticky;
    position: sticky;
  }
`;

export const StyledNavLink = styled(NavLink)`
  background-color: ${props =>
    props.active
      ? props.theme.activeBackgroundColor
      : props.disabled
      ? props.theme.disabledBackgroundColor
      : 'none'};
  color: ${props =>
    props.active ? props.theme.activeColor : props.theme.navLinkColor};

  &:hover {
    color: ${props =>
      props.active ? props.theme.activeColor : props.theme.navLinkHoverColor};
  }
`;

function Sidebar({ questionnaire, history }) {
  const questionnaireResponse = useSelector(getQuestionnaireResponse);
  const sectionStatuses = useSelector(
    selectSectionStatuses(questionnaire.uuid)
  );

  const authenticated = isAuthenticated();

  const isAdmin = isExchangeAdmin();

  const sectionsByCategory = groupBy(
    questionnaire.sections,
    section => section.category
  );

  const allSectionsCompleted =
    sectionStatuses &&
    every(
      questionnaire.sections,
      section => sectionStatuses[section.uuid] === STATUS_COMPLETED
    );

  return (
    <SidebarWrapper>
      <StickySidebar>
        <NavbarBrand
          tag={Link}
          to={isAdmin ? '/admin' : '/'}
          className="w-100 mb-5 px-4"
        >
          <img src={logo} alt="logo" />
        </NavbarBrand>
        <Switch>
          <Route
            path="/admin"
            render={() => <AdminSidebar authenticated={authenticated} />}
          />
          <Route
            path="/"
            render={() => (
              <UserSidebar
                authenticated={authenticated}
                sectionsByCategory={sectionsByCategory}
                allSectionsCompleted={allSectionsCompleted}
                sectionStatuses={sectionStatuses}
                questionnaireResponse={questionnaireResponse}
                history={history}
                isAdmin={isAdmin}
              />
            )}
          />
        </Switch>
        <Nav vertical className="my-5">
          <NavItem className="border-top">
            <StyledNavLink tag={Link} to={isAdmin ? '/admin/help' : '/help'}>
              Help / Support
            </StyledNavLink>
          </NavItem>
          {isAdmin && (
            <NavItem className="border-top border-bottom">
              <StyledNavLink tag={Link} to="/admin">
                Admin
              </StyledNavLink>
            </NavItem>
          )}
          <NavItem className="border-top border-bottom">
            <StyledNavLink tag={Link} to="/logout">
              Logout
            </StyledNavLink>
          </NavItem>
        </Nav>
      </StickySidebar>
    </SidebarWrapper>
  );
}

Sidebar.propTypes = propTypes;
Sidebar.defaultProps = defaultProps;

export default withRouter(Sidebar);

import React from 'react';
import { Nav } from 'reactstrap';
import find from 'lodash/find';
import map from 'lodash/map';
import { Link, matchPath, withRouter } from 'react-router-dom';
import { STATUS_COMPLETED, STATUS_NOT_STARTED } from 'utils/constants';
import NavItem from 'reactstrap/es/NavItem';
import { StyledNavLink } from './index';
import PropTypes from 'prop-types';
import { ROUTE_PREFIX_SECTION } from 'utils/questionnaireHelpers';

const propTypes = {
  authenticated: PropTypes.bool.isRequired,
  questionnaireResponse: PropTypes.object,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  sectionsByCategory: PropTypes.object.isRequired,
  sectionStatuses: PropTypes.object,
  allSectionsCompleted: PropTypes.bool,
  isAdmin: PropTypes.bool.isRequired
};

const defaultProps = {};

function Sidebar({
  authenticated,
  questionnaireResponse,
  sectionsByCategory,
  sectionStatuses,
  history,
  allSectionsCompleted,
  isAdmin
}) {
  const match = matchPath(history.location.pathname, {
    path: `/application/:questionnaireId${ROUTE_PREFIX_SECTION}/:category/:slug`,
    exact: true,
    strict: false
  });

  return (
    <>
      {authenticated && (
        <>
          {!isAdmin && (
            <Nav vertical className="mb-5">
              <NavItem className="border-top border-bottom">
                <StyledNavLink
                  tag={Link}
                  to="/"
                  active={history.location.pathname === '/'}
                >
                  {questionnaireResponse
                    ? 'Application Overview'
                    : 'Start an Application'}
                </StyledNavLink>
              </NavItem>
            </Nav>
          )}
          <Nav vertical>
            {map(sectionsByCategory, (sections, categoryName) => {
              // Determine whether this category is available yet or not,
              // based on the progress of the questionnaire.
              const isStartedOrComplete =
                sectionStatuses &&
                !!find(
                  sections,
                  section =>
                    sectionStatuses[section.uuid] !== STATUS_NOT_STARTED
                );
              const previousSectionComplete =
                !sections[0].prevSection ||
                (sectionStatuses &&
                  sectionStatuses[sections[0].prevSection] ===
                    STATUS_COMPLETED);

              // todo: if questionnaire response is submitted, allow access to all menu items.
              const categorySlug = sections[0].categorySlug;
              const path = sections[0].path.replace(
                ':questionnaireId',
                questionnaireResponse && questionnaireResponse._id
              );

              const isActive =
                match && match.params && match.params.category === categorySlug;

              return (
                <NavItem
                  active={isActive}
                  key={categorySlug}
                  className="border-top"
                >
                  <StyledNavLink
                    tag={Link}
                    to={path}
                    active={isActive}
                    disabled={
                      !questionnaireResponse ||
                      (!isStartedOrComplete && !previousSectionComplete)
                    }
                  >
                    {categoryName}
                  </StyledNavLink>
                </NavItem>
              );
            })}
            {/*<NavItem*/}
            {/*  active={history.location.pathname === '/agreements'}*/}
            {/*  className="border-top border-bottom"*/}
            {/*>*/}
            {/*  <StyledNavLink*/}
            {/*    tag={Link}*/}
            {/*    to="/agreements"*/}
            {/*    active={history.location.pathname === '/agreements'}*/}
            {/*    disabled={!allSectionsCompleted}*/}
            {/*  >*/}
            {/*    Agreements / Disclosures*/}
            {/*  </StyledNavLink>*/}
            {/*</NavItem>*/}
          </Nav>
        </>
      )}
    </>
  );
}

Sidebar.propTypes = propTypes;
Sidebar.defaultProps = defaultProps;

export default withRouter(Sidebar);

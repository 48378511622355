import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import {
  Row,
  Col,
  Card,
  CardTitle,
  CardSubtitle,
  CardText,
  CardBody
} from 'reactstrap';
import { H1, H3 } from 'components/Heading';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Contact = styled.a`
  display: block;
  width: 100%;

  @media (max-width: 576px) {
    font-size: 0.875rem;
  }
`;

const StyledH3 = styled(H3)`
  margin-bottom: 0.625rem;
  padding: 0 0.9375rem;
  width: 100%;
`;

const StyledCol = styled(Col)`
  margin-bottom: 2.5rem;
`;

const StyledRow = styled(Row)`
  width: 100%;
`;

const StyledCardTitle = styled(CardTitle)`
  font-weight: 600;
  margin-bottom: 0.625rem;
`;

const StyledCardSubtitle = styled(CardSubtitle)`
  margin-bottom: 0.25rem;
`;

function HelpSupportPage() {
  return (
    <Container>
      <Helmet>
        <title>Help/Support</title>
      </Helmet>
      <H1>Help / Support</H1>
      <StyledRow>
        <StyledH3>President / CEO</StyledH3>
        <StyledCol md={12} lg={6} xl={5}>
          <Card>
            <CardBody>
              <StyledCardTitle>Donald Roberts</StyledCardTitle>
              <StyledCardSubtitle>President and CEO</StyledCardSubtitle>
              <CardText>
                <Contact href="mailto:donald.roberts@smallexchange.com">
                  donald.roberts@smallexchange.com
                </Contact>
                <Contact href="tel:312-858-7001">(312) 858-7001</Contact>
              </CardText>
            </CardBody>
          </Card>
        </StyledCol>
      </StyledRow>

      <StyledRow>
        <StyledH3>Operations</StyledH3>
        <StyledCol md={12} lg={6} xl={5}>
          <Card>
            <CardBody>
              <StyledCardTitle>John Hever</StyledCardTitle>
              <StyledCardSubtitle>
                Managing Director - Operations
              </StyledCardSubtitle>
              <CardText>
                <Contact href="mailto:john@smallexchange.com">
                  john@smallexchange.com
                </Contact>
                <Contact href="tel:312-858-7002">(312) 858-7002</Contact>
              </CardText>
            </CardBody>
          </Card>
        </StyledCol>
      </StyledRow>

      <StyledRow>
        <StyledH3>Membership / General Support</StyledH3>
        <StyledCol md={12} lg={6} xl={5}>
          <Card>
            <CardBody>
              <StyledCardTitle>Natalie Oblazny</StyledCardTitle>
              <StyledCardSubtitle>
                Director of Operations - Member Support
              </StyledCardSubtitle>
              <CardText>
                <Contact href="mailto:natalie.oblazny@smallexchange.com">
                  natalie.oblazny@smallexchange.com
                </Contact>
                <Contact href="tel:312-756-8626">(312) 756-8626</Contact>
              </CardText>
            </CardBody>
          </Card>
        </StyledCol>
        <StyledCol md={12} lg={6} xl={5}>
          <Card>
            <CardBody>
              <StyledCardTitle>Maggie Pinkawa</StyledCardTitle>
              <StyledCardSubtitle>Project Manager</StyledCardSubtitle>
              <CardText>
                <Contact href="mailto:maggie.pinkawa@smallexchange.com">
                  maggie.pinkawa@smallexchange.com
                </Contact>
                <Contact href="tel:312-761-4137">(312) 761-4137</Contact>
              </CardText>
            </CardBody>
          </Card>
        </StyledCol>
      </StyledRow>

      <StyledRow>
        <StyledH3>Technology</StyledH3>
        <StyledCol md={12} lg={6} xl={5}>
          <Card>
            <CardBody>
              <StyledCardTitle>Linwood Ma</StyledCardTitle>
              <StyledCardSubtitle>Chief Technical Officer</StyledCardSubtitle>
              <CardText>
                <Contact href="mailto:linwood@smallexchange.com">
                  linwood@smallexchange.com
                </Contact>
                <Contact href="tel:312-858-6994">(312) 858-6994</Contact>
              </CardText>
            </CardBody>
          </Card>
        </StyledCol>
        <StyledCol md={12} lg={6} xl={5}>
          <Card>
            <CardBody>
              <StyledCardTitle>Michael Kantowski</StyledCardTitle>
              <StyledCardSubtitle>Head of IT</StyledCardSubtitle>
              <CardText>
                <Contact href="mailto:mkantowski@smallexchange.com">
                  mkantowski@smallexchange.com
                </Contact>
                <Contact href="tel:312-858-5685">(312) 858-5685</Contact>
              </CardText>
            </CardBody>
          </Card>
        </StyledCol>
        <StyledCol md={12} lg={6} xl={5}>
          <Card>
            <CardBody>
              <StyledCardTitle>Alex Gonzalez</StyledCardTitle>
              <StyledCardSubtitle>
                Director of Tech Operations
              </StyledCardSubtitle>
              <CardText>
                <Contact href="mailto:agonzalez@smallexchange.com">
                  agonzalez@smallexchange.com
                </Contact>
                <Contact href="312-761-1517">(312) 761-1517</Contact>
              </CardText>
            </CardBody>
          </Card>
        </StyledCol>
      </StyledRow>

      <StyledRow>
        <StyledH3>Regulatory / Compliance / Surveillance</StyledH3>
        <StyledCol md={12} lg={6} xl={5}>
          <Card>
            <CardBody>
              <StyledCardTitle>Peter Santori</StyledCardTitle>
              <StyledCardSubtitle>Chief Regulatory Officer</StyledCardSubtitle>
              <CardText>
                <Contact href="mailto:pete@smallexchange.com">
                  pete@smallexchange.com
                </Contact>
                <Contact href="tel:312-761-1660">(312) 761-1660</Contact>
              </CardText>
            </CardBody>
          </Card>
        </StyledCol>
        <StyledCol md={12} lg={6} xl={5}>
          <Card>
            <CardBody>
              <StyledCardTitle>Megan McGrath</StyledCardTitle>
              <StyledCardSubtitle>
                Managing Director of Compliance
              </StyledCardSubtitle>
              <CardText>
                <Contact href="mailto:megan.mcgrath@smallexchange.com">
                  megan.mcgrath@smallexchange.com
                </Contact>
                <Contact href="tel:312-796-8393">(312) 796-8393</Contact>
              </CardText>
            </CardBody>
          </Card>
        </StyledCol>
        <StyledCol md={12} lg={6} xl={5}>
          <Card>
            <CardBody>
              <StyledCardTitle>James Dore</StyledCardTitle>
              <StyledCardSubtitle>
                Director of Market Surveillance
              </StyledCardSubtitle>
              <CardText>
                <Contact href="mailto:james.dore@smallexchange.com">
                  james.dore@smallexchange.com
                </Contact>
                <Contact href="312-366-3469">(312) 366-3469</Contact>
              </CardText>
            </CardBody>
          </Card>
        </StyledCol>
      </StyledRow>
    </Container>
  );
}

export default HelpSupportPage;

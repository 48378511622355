import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import Question from '../../index';
import find from 'lodash/find';
import filter from 'lodash/filter';
import isNil from 'lodash/isNil';

const propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  question: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    branchMapSummary: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    localizations: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    ).isRequired,
    identifier: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired
  })
};

const defaultProps = {};

const defaultFieldValues = {};

function ContactMappedResponseQuestion({
  question: {
    responseTypeDef: { templateJSON }
  },
  onChange,
  value,
  meta: { touched, errors }
}) {
  const initialState = Object.assign({}, defaultFieldValues, value || {});
  const [responseData, setResponseData] = useState(initialState);

  // Update responseData state if value prop changes.
  useEffect(() => {
    const newValue = value;

    if (newValue !== responseData) {
      setResponseData(Object.assign({}, defaultFieldValues, newValue || {}));
    }
  }, [value]);

  // Call onChange when a question onSave is called.
  const onChangeCallback = useCallback(
    (key, data) => {
      const newResponseData = {
        ...responseData,
        [key]: data
      };

      setResponseData(newResponseData);

      return onChange
        ? onChange(newResponseData)
        : Promise.resolve(newResponseData);
    },
    [responseData, setResponseData, onChange]
  );

  const findErrors = (errors, key) => {
    if (!errors) {
      return undefined;
    }

    const found = find(errors, e => e.key === key);

    if (!found) {
      return undefined;
    }

    return filter(found.value, v => !isNil(v));
  };

  const questions = templateJSON.questions;

  return (
    <Fragment>
      <Row>
        <Col sm={2}>
          <Question
            question={questions.areaCode}
            value={responseData && responseData.areaCode}
            onSave={data => onChangeCallback('areaCode', data)}
            errors={findErrors(errors, 'areaCode')}
          />
        </Col>
        <Col sm={6}>
          <Question
            question={questions.phoneNumber}
            value={responseData && responseData.phoneNumber}
            onSave={data => onChangeCallback('phoneNumber', data)}
            errors={findErrors(errors, 'phoneNumber')}
          />
        </Col>
        <Col sm={2}>
          <Question
            question={questions.extension}
            value={responseData && responseData.extension}
            onSave={data => onChangeCallback('extension', data)}
            errors={findErrors(errors, 'extension')}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Question
            question={questions.email}
            value={responseData && responseData.email}
            onSave={data => onChangeCallback('email', data)}
            errors={findErrors(errors, 'email')}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Question
            question={questions.website}
            value={responseData && responseData.website}
            onSave={data => onChangeCallback('website', data)}
            errors={findErrors(errors, 'website')}
          />
        </Col>
      </Row>
    </Fragment>
  );
}

ContactMappedResponseQuestion.propTypes = propTypes;
ContactMappedResponseQuestion.defaultProps = defaultProps;

export default ContactMappedResponseQuestion;

const colors = {
  red: '#e02729',
  gray: '#ededed'
};

export default {
  colors,
  fontFamily: "'Open Sans', sans-serif",
  fontFamilyCondensed: "'Open Sans Condensed', sans-serif",
  fontLight: 300,
  fontNormal: 400,
  fontSemibold: 600,
  fontBold: 700,
  navLinkColor: '#212529',
  navLinkHoverColor: colors.red,
  activeColor: '#fff',
  activeBackgroundColor: colors.red,
  disabledBackgroundColor: '#c6c8ca'
};

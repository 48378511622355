import kebabCase from 'lodash/kebabCase';
import keyBy from 'lodash/keyBy';
import find from 'lodash/find';
import questionnaires from 'questionnaire';
import baseQuestions from 'questions';

export const ROUTE_PREFIX_SECTION = '/section';

const questionnaire = {
  ...questionnaires[0],
  sections: questionnaires[0].sections.map((section, index, allSections) => {
    const slug = slugify(section.name);

    // Get the next section uuid, if there is one.
    const nextSection =
      index + 1 < allSections.length ? allSections[index + 1].uuid : null;

    const prevSection = index - 1 > 0 ? allSections[index - 1].uuid : null;

    // Set slugs and path if they are not set already.
    return {
      slug,
      categorySlug: slugify(section.category),
      path: `/application/:questionnaireId${makeUrlForSection(section)}`,
      nextSection,
      prevSection,
      ...section
    };
  })
};

const questions = baseQuestions;

export function getQuestionnaire() {
  return questionnaire;
}

export function getQuestionnaireByUuid(questionnaireUuid) {
  let mappedQuestionnaire = {};
  questionnaires.map(questionnaire => {
    if (questionnaireUuid === questionnaire.uuid) {
      mappedQuestionnaire = {
        ...questionnaire,
        sections: questionnaire.sections.map((section, index, allSections) => {
          const slug = slugify(section.name);

          // Get the next section uuid, if there is one.
          const nextSection =
            index + 1 < allSections.length ? allSections[index + 1].uuid : null;

          const prevSection =
            index - 1 > 0 ? allSections[index - 1].uuid : null;

          // Set slugs and path if they are not set already.
          return {
            slug,
            categorySlug: slugify(section.category),
            path: `/application/:questionnaireId${makeUrlForSection(section)}`,
            nextSection,
            prevSection,
            ...section
          };
        })
      };
    }
  });
  return mappedQuestionnaire;
}

export function getQuestionnaireVersion(questionnaireUuid) {
  return getQuestionnaireByUuid(questionnaireUuid).version;
}

/**
 * @return {Array}
 */
export function getAllQuestions() {
  return questions;
}

export function getAllQuestionsKeyedByUuid() {
  return keyBy(getAllQuestions().map(item => item[1]), 'uuid');
}

export function getQuestion(uuid) {
  const question = getAllQuestions().find(item => uuid === item[0]);

  return question ? question[1] : null;
}

export function makeUrlForSection({ category, name }) {
  return `${ROUTE_PREFIX_SECTION}/${slugify(category)}/${slugify(name)}`;
}

export function slugify(text) {
  return kebabCase(text);
}

export function getSections(questionnaireUuid) {
  return getQuestionnaireByUuid(questionnaireUuid).sections;
}

export function getSectionByUuid(uuid, questionnaireUuid) {
  return getQuestionnaireByUuid(questionnaireUuid).sections.find(
    section => uuid === section.uuid
  );
}

export function findSectionByCategoryAndSlug(
  categorySlug,
  slug,
  questionnaireUuid
) {
  return getQuestionnaireByUuid(questionnaireUuid).sections.find(
    section => categorySlug === section.categorySlug && slug === section.slug
  );
}

export function getQuestionLocalization(question, locale = null) {
  if (!question.localizations || question.localizations.length === 0) {
    return null;
  }

  locale = locale || question.defaultLocale;

  return question.localizations.find(
    localization => localization.locale === locale
  );
}

export function getAllResponseIdsWithQuestions() {
  const questionnaire = getQuestionnaire();

  return questionnaire.sections.reduce((responseIds, section) => {
    const sectionResponseIds = section.questions.reduce(
      (sectionResponseIds, [questionId, responseId]) => {
        sectionResponseIds[responseId] = getQuestion(questionId);
        return sectionResponseIds;
      },
      {}
    );

    return { ...responseIds, ...sectionResponseIds };
  }, {});
}

import React from 'react';
import PropTypes from 'prop-types';
import { questionShape } from 'utils/shapes';
import COUNTRY_LIST from 'utils/countryList';
import SelectQuestion from 'components/Question/components/SelectQuestion';

const propTypes = {
  question: questionShape.isRequired,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  hideSummary: PropTypes.bool,
  saving: PropTypes.string
};

const defaultProps = {};

const countryBranchKeys = COUNTRY_LIST.reduce(
  (obj, { code, name }) => {
    obj[code] = name;

    return obj;
  },
  { '': 'Select country' }
);

function CountrySelectQuestion({ question, hideSummary, ...props }) {
  const updatedQuestion = {
    ...question,
    responseTypeDef: {
      ...question.responseTypeDef,
      branchKeys: countryBranchKeys
    }
  };

  return <SelectQuestion question={updatedQuestion} hideSummary {...props} />;
}

CountrySelectQuestion.propTypes = propTypes;
CountrySelectQuestion.defaultProps = defaultProps;

export default CountrySelectQuestion;

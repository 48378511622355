import { useSelector } from 'react-redux';
import { getAnswerByQuestionUuid } from '../selectors/answer';
import includes from 'lodash/includes';

export const useIsRequired = question => {
  const answer = useSelector(
    getAnswerByQuestionUuid(question.required && question.required.questionUuid)
  );

  return isMappedRequired(question, answer);
};

export const isMappedRequired = (question, answer) =>
  answer && includes(question.required.conditions, answer.responseData);

import keyBy from 'lodash/keyBy';
import pick from 'lodash/pick';
import { RESOURCE_URL as BASE_RESOURCE_URL } from 'query-configs/questionnaireResponse';
import { httpMethods } from 'redux-query';

// Use questionnaireResponse resource url as base, since this is a nested resource.
export const makeResourceUrl = questionnaireResponseId =>
  `${BASE_RESOURCE_URL}/${questionnaireResponseId}/answers`;

// Query config for retrieving all questionnaire responses
export const answersRequest = questionnaireResponseId => {
  if (!questionnaireResponseId) {
    return null;
  }

  return {
    url: makeResourceUrl(questionnaireResponseId),
    transform: body => {
      return {
        answersByResponseUuid: keyBy(body, 'responseUuid')
      };
    },
    update: {
      answersByResponseUuid: (oldValue, newValue) => {
        return keyBy(newValue, 'responseUuid');
      }
    }
  };
};

// Query config for making a new questionnaire response
export const createAnswerMutation = (questionnaireResponseId, answer) => ({
  url: makeResourceUrl(questionnaireResponseId),
  body: pick(answer, [
    'responseUuid',
    'questionUuid',
    'questionVersion',
    'questionnaireVersion',
    'responseData'
  ]),
  transform: body => ({
    answersByResponseUuid: {
      [body.responseUuid]: body
    }
  }),
  update: {
    answersByResponseUuid: (oldValue, newValue) => ({
      ...oldValue,
      ...newValue
    })
  },
  optimisticUpdate: {
    answersByResponseUuid: prevValue => ({
      ...prevValue,
      [answer.responseUuid]: {
        ...answer,
        questionnaireResponseId
      }
    })
  }
});

export const updateAnswerMutation = (questionnaireResponseId, answer) => ({
  url: makeResourceUrl(answer.questionnaireResponseId) + `/${answer._id}`,
  options: {
    method: httpMethods.PUT
  },
  body: pick(answer, [
    '_id',
    'responseUuid',
    'questionUuid',
    'questionVersion',
    'questionnaireVersion',
    'responseData'
  ]),
  transform: body => ({
    answersByResponseUuid: {
      [body.responseUuid]: body
    }
  }),
  update: {
    answersByResponseUuid: (oldValue, newValue) => ({
      ...oldValue,
      ...newValue
    })
  },
  optimisticUpdate: {
    answersByResponseUuid: prevValue => ({
      ...prevValue,
      [answer.responseUuid]: answer
    })
  }
});

import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import Question from '../../index';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';

const propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  question: PropTypes.shape({
    uuid: PropTypes.string,
    summary: PropTypes.string,
    branchMapSummary: PropTypes.string,
    required: PropTypes.bool,
    localizations: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    ),
    identifier: PropTypes.string,
    defaultLocale: PropTypes.string
  })
};

const defaultProps = {};

const defaultData = { country: 'United States' };

function AddressQuestion({ question, onChange, value, meta: { errors } }) {
  const label = getSelectValueFromKey(
    question.addressPurpose.responseTypeDef.branchKeys,
    value.addressPurpose
  );

  const initialState = { ...defaultData, ...(value || {}) };
  const [addressData, setAddressData] = useState(initialState);

  // Update addressData state if value prop changes.
  useEffect(() => {
    setAddressData({ ...defaultData, ...(value || {}) });
  }, [value]);

  // Call onChange when a question onSave is called.
  const onChangeCallback = useCallback(
    (key, data) => {
      const newAddressData = {
        ...addressData,
        [key]: data
      };

      setAddressData(newAddressData);

      if (onChange) {
        onChange(newAddressData);
      }
    },
    [addressData, setAddressData, onChange]
  );

  return (
    <Fragment>
      <h3> Address: {label} </h3>
      <Row>
        <Col sm={6}>
          <Question
            question={question.addressPurpose}
            hideSummary={true}
            value={addressData && addressData.addressPurpose}
            onSave={data => {
              onChangeCallback('addressPurpose', data);
            }}
            errors={filter(errors, e => e.key === 'addressPurpose').map(
              row => row.value
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Question
            question={question.addressLine1}
            value={addressData && addressData.addressLine1}
            onSave={data => onChangeCallback('addressLine1', data)}
            errors={getErrors(errors, 'addressLine1').map(row => row.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Question
            question={question.addressLine2}
            value={addressData && addressData.addressLine2}
            onSave={data => onChangeCallback('addressLine2', data)}
            errors={filter(errors, e => e.key === 'addressLine2').map(
              row => row.value
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Question
            question={question.addressLine3}
            value={addressData && addressData.addressLine3}
            onSave={data => onChangeCallback('addressLine3', data)}
            errors={filter(errors, e => e.key === 'addressLine3').map(
              row => row.value
            )}
          />
        </Col>
        <Col sm={6}>
          <Question
            question={question.city}
            value={addressData && addressData.city}
            onSave={data => onChangeCallback('city', data)}
            errors={filter(errors, e => e.key === 'city').map(row => row.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Question
            question={question.country}
            value={(addressData && addressData.country) || 'US'}
            onSave={data => onChangeCallback('country', data)}
            errors={filter(errors, e => e.key === 'country').map(
              row => row.value
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Question
            question={question.province}
            value={addressData && addressData.province}
            onSave={data => onChangeCallback('province', data)}
            errors={filter(errors, e => e.key === 'province').map(
              row => row.value
            )}
          />
        </Col>
        <Col sm={6}>
          <Question
            question={question.zipCode}
            value={addressData && addressData.zipCode}
            onSave={data => onChangeCallback('zipCode', data)}
            errors={filter(errors, e => e.key === 'zipCode').map(
              row => row.value
            )}
          />
        </Col>
      </Row>
    </Fragment>
  );
}

const getErrors = (errors, key) => {
  return filter(errors, e => e.key === key);
};

const getSelectValueFromKey = (options, key) => {
  if (options.hasOwnProperty(key)) {
    return options[key];
  }
};

AddressQuestion.propTypes = propTypes;
AddressQuestion.defaultProps = defaultProps;

export default AddressQuestion;

import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { questionShape } from 'utils/shapes';
import { FormGroup, CustomInput, FormFeedback } from 'reactstrap';
import upperFirst from 'lodash/upperFirst';
import QuestionBody from './QuestionBody';
import BranchMap from './BranchMap';
import QuestionLabel from './QuestionLabel';

const EnumInputWrapper = styled.div`
  label {
    &:hover {
      cursor: pointer;
    }
  }
`;

const defaultOptions = {
  horizontal: false,
  align: null
};

const propTypes = {
  question: questionShape.isRequired,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  value: PropTypes.string,
  saving: PropTypes.string,
  disabled: PropTypes.bool
};

const defaultProps = {};

function EnumQuestion({
  question,
  onChange,
  onSave,
  value,
  saving,
  disabled,
  meta: { touched, errors }
}) {
  const singleOption = question.responseTypeDef.branchKeys.length === 1;
  const invalid = touched && errors && errors.length > 0;

  const options = {
    ...defaultOptions,
    ...(question.responseTypeDef.options || {})
  };

  const onChangeCallback = useCallback(
    e => {
      let newValue;

      if (singleOption) {
        newValue = e.target.checked ? e.target.value : null;
      } else {
        newValue = e.target.value;
      }

      onChange && onChange(newValue);
      return onSave && onSave(newValue);
    },
    [singleOption, onChange, onSave]
  );

  return (
    <>
      <FormGroup>
        <QuestionLabel question={question} saving={saving}>
          {question.summary}
        </QuestionLabel>
        <QuestionBody question={question} />
        <EnumInputWrapper className={options.align && `text-${options.align}`}>
          {question.responseTypeDef.branchKeys.map((branchKey, index) => (
            <CustomInput
              type={singleOption ? 'checkbox' : 'radio'}
              id={`${question.uuid}_${index}`}
              name={question.uuid}
              label={upperFirst(branchKey)}
              value={branchKey}
              onChange={onChangeCallback}
              key={branchKey}
              checked={value === branchKey}
              inline={options.horizontal}
              disabled={disabled}
              invalid={invalid}
            />
          ))}
        </EnumInputWrapper>
        {invalid &&
          errors.map(error => (
            <FormFeedback key={`error_${error}`}>{error}</FormFeedback>
          ))}
      </FormGroup>
      <BranchMap question={question} value={value} />
    </>
  );
}

EnumQuestion.propTypes = propTypes;
EnumQuestion.defaultProps = defaultProps;

export default EnumQuestion;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { find } from 'lodash';
import {
  Button,
  Row,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  Badge,
  FormText
} from 'reactstrap';
import {
  STATUS_DENIED,
  STATUS_NEEDS_ATTENTION,
  STATUS_APPROVED,
  STATUS_UNSUBMITTED,
  STATUS_SUBMITTED
} from 'utils/constants';
import { useSelector } from 'react-redux';
import { getQuestionnaireResponse } from 'selectors/questionnaireResponse';
import { updateQuestionnaireResponseMutation } from 'query-configs/questionnaireResponse';
import { useMutation, useRequest } from 'redux-query-react';
import { withRouter } from 'react-router-dom';
import { H4 } from 'components/Heading';
import store from 'store';
import { questionnaireResponseDocumentsRequest } from '../../query-configs/questionnaireResponseDocument';
import { getQuestionnaireResponseDocuments } from '../../selectors/questionnaireResponseDocument';
import { QUESTIONNAIRE_RESPONSE_DOCUMENT_TYPES } from '../../utils/constants';
import { buildUrl } from '../../utils/functions';
import { getToken } from '../../utils/auth';

const AdminMenuCard = styled(Card)`
  position: sticky;
  top: 0;
  margin-bottom: 3rem;
  z-index: 200;
`;

const AdminMenuTextArea = styled(Input)`
  width: 100%;
`;

const AdminMenuFormGroup = styled(FormGroup)`
  width: 90%;
`;

const AdminRow = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const statusColors = {
  [STATUS_UNSUBMITTED]: 'secondary',
  [STATUS_SUBMITTED]: 'primary',
  [STATUS_DENIED]: 'danger',
  [STATUS_NEEDS_ATTENTION]: 'warning',
  [STATUS_APPROVED]: 'success'
};

const states = [
  {
    key: statusColors[STATUS_DENIED],
    label: 'Deny',
    status: STATUS_DENIED
  },
  {
    key: statusColors[STATUS_NEEDS_ATTENTION],
    label: 'Needs Attention',
    status: STATUS_NEEDS_ATTENTION
  },
  {
    key: statusColors[STATUS_APPROVED],
    label: 'Approve',
    status: STATUS_APPROVED
  }
];

const initialState = 'none';

function SeparatorBullet() {
  return <small className="mx-2 text-muted">&bull;</small>;
}

function getAdminNotesStorageKey(questionnaireResponse) {
  return questionnaireResponse && `admin_notes_${questionnaireResponse._id}`;
}

function getAdminNotesFromStorage(questionnaireResponse) {
  const key = getAdminNotesStorageKey(questionnaireResponse);
  return key && store.get(key);
}

const DownloadLink = ({ questionnaireResponse, title, type }) => {
  if (!questionnaireResponse || !questionnaireResponse._id) {
    return null;
  }
  return (
    <>
      <small className="ml-4 text-muted">
        <a
          href={buildUrl(
            '/api/questionnaire-responses/:questionnaireResponseId/documents/download.:type',
            {
              questionnaireResponseId: questionnaireResponse._id,
              type
            },
            {
              bearerToken: getToken()
            }
          )}
        >
          {title}
        </a>
      </small>
      <SeparatorBullet />
    </>
  );
};

function AdminMenu({ history: { push } }) {
  const questionnaireResponse = useSelector(getQuestionnaireResponse);

  // eslint-disable-next-line no-unused-vars
  const [{ isFinished: responsesRequestFinished }] = useRequest(
    questionnaireResponseDocumentsRequest(
      questionnaireResponse && questionnaireResponse._id
    )
  );

  const initialAdminNotes =
    getAdminNotesFromStorage(questionnaireResponse) ||
    (questionnaireResponse && questionnaireResponse.adminNotes) ||
    initialState;

  const [status, setStatus] = useState(initialState);
  const [adminNotes, setAdminNotes] = useState(initialAdminNotes);

  useEffect(() => {
    if (questionnaireResponse) {
      const fromStorage = getAdminNotesFromStorage(questionnaireResponse);

      // Use admin notes from local storage if they are set, otherwise use the questionnaire response admin notes.
      setAdminNotes(
        fromStorage || questionnaireResponse.adminNotes || initialState
      );
    }
  }, [questionnaireResponse]);

  // eslint-disable-next-line no-unused-vars
  const [_, makeQuestionnaireResponse] = useMutation((status, adminNotes) => {
    const newState = Object.assign({}, questionnaireResponse, {
      status,
      adminNotes
    });

    return updateQuestionnaireResponseMutation(newState);
  });

  return (
    <AdminMenuCard color="light">
      <CardHeader>
        <div className="d-flex align-items-end">
          <H4 className="mb-0 mr-3">Admin Controls</H4>{' '}
          {questionnaireResponse && (
            <>
              {questionnaireResponse.firmId && (
                <>
                  <small className="ml-4 text-muted">
                    Keycloak Firm:{' '}
                    <strong>{questionnaireResponse.firmId.name}</strong>{' '}
                    <span className="ml-1">
                      ({questionnaireResponse.firmId.firmType})
                    </span>
                  </small>
                  <SeparatorBullet />
                </>
              )}
              <DownloadLink
                questionnaireResponse={questionnaireResponse}
                type={`csv`}
                title="Download CSV Answers"
              />
              <DownloadLink
                questionnaireResponse={questionnaireResponse}
                type={`pdf`}
                title="Download PDF Answers"
              />
              <span>
                <Badge color={statusColors[questionnaireResponse.status]}>
                  Status:{' '}
                  {questionnaireResponse.status.toUpperCase().replace('_', ' ')}
                </Badge>
              </span>
              {questionnaireResponse.status !== STATUS_UNSUBMITTED && (
                <div className="ml-auto">
                  {states.map((s, key) => {
                    return (
                      <Button
                        key={`admin_menu_status_button_${key}`}
                        color={s.key}
                        onClick={() => {
                          setStatus(s.status);
                        }}
                        disabled={status === s.status}
                        size="sm"
                        className="mx-2"
                      >
                        {s.label}
                      </Button>
                    );
                  })}
                </div>
              )}
            </>
          )}
        </div>
      </CardHeader>
      {(status === STATUS_DENIED ||
        status === STATUS_NEEDS_ATTENTION ||
        status === STATUS_APPROVED) && (
        <CardBody>
          <AdminRow>
            {(status === STATUS_DENIED ||
              status === STATUS_NEEDS_ATTENTION) && (
              <AdminMenuFormGroup>
                <Label for="adminNotes">Admin Notes</Label>
                <FormText color="muted">
                  Provide notes here that the user(s) will be able to see when
                  they come back to their application.
                </FormText>
                <AdminMenuTextArea
                  type="textarea"
                  name="adminNotes"
                  id="adminNotes"
                  rows={10}
                  value={adminNotes}
                  onChange={e => {
                    setAdminNotes(e.target.value);
                    store.set(
                      getAdminNotesStorageKey(questionnaireResponse),
                      e.target.value
                    );
                  }}
                />
              </AdminMenuFormGroup>
            )}
          </AdminRow>
          <AdminRow>
            {status === STATUS_APPROVED && (
              <>
                <Button
                  color="primary"
                  onClick={() => setStatus(initialState)}
                  className="mx-2"
                >
                  Cancel
                </Button>
                <Button
                  color="secondary"
                  onClick={() =>
                    makeQuestionnaireResponse(STATUS_APPROVED).then(() =>
                      push('/admin/applications')
                    )
                  }
                  className="mx-2"
                >
                  Approve Application
                </Button>
              </>
            )}
            {(status === STATUS_DENIED ||
              status === STATUS_NEEDS_ATTENTION) && (
              <>
                <Button
                  color="primary"
                  outline
                  onClick={() => setStatus(initialState)}
                  className="mr-3"
                >
                  Close
                </Button>
                <Button
                  color="secondary"
                  onClick={() =>
                    makeQuestionnaireResponse(status, adminNotes).then(() =>
                      push('/admin/applications')
                    )
                  }
                >
                  Confirm & Submit
                </Button>
              </>
            )}
          </AdminRow>
        </CardBody>
      )}
    </AdminMenuCard>
  );
}

export default withRouter(AdminMenu);

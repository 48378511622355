import React from 'react';
import PropTypes from 'prop-types';
import { questionShape } from 'utils/shapes';
import { FormGroup, CustomInput, Input, FormFeedback } from 'reactstrap';
import QuestionLabel from './QuestionLabel';
import BranchMap from 'components/Question/components/BranchMap';
import styled from 'styled-components';

const propTypes = {
  question: questionShape.isRequired,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  hideSummary: PropTypes.bool,
  saving: PropTypes.string
};

const defaultProps = {};

const PlaceHolderOption = styled.option`
  display: none;
`;

function SelectQuestion({
  question,
  onChange,
  onSave,
  value,
  disabled,
  hideSummary,
  saving,
  meta: { touched, errors }
}) {
  const invalid = touched && errors && errors.length > 0;

  return (
    <>
      <FormGroup>
        {!hideSummary && (
          <QuestionLabel question={question} saving={saving}>
            {question.summary}
          </QuestionLabel>
        )}
        <CustomInput
          type="select"
          name={question.uuid}
          id={question.uuid}
          disabled={disabled}
          value={value || ''}
          onChange={e => {
            onChange && onChange(e.target.value);
            onSave && onSave(e.target.value);
          }}
          invalid={invalid}
        >
          <PlaceHolderOption disabled hidden value="">
            {' '}
            -- select an option --{' '}
          </PlaceHolderOption>
          {Object.keys(question.responseTypeDef.branchKeys).map(branchKey => {
            return (
              <option
                id={`${question.uuid}_${branchKey}`}
                key={`${question.uuid}_${branchKey}`}
                value={branchKey}
              >
                {question.responseTypeDef.branchKeys[branchKey]}
              </option>
            );
          })}
        </CustomInput>
        {invalid &&
          errors.map(error => (
            <FormFeedback key={`error_${error}`}>{error}</FormFeedback>
          ))}
      </FormGroup>
      <BranchMap question={question} value={value} />
    </>
  );
}

SelectQuestion.propTypes = propTypes;
SelectQuestion.defaultProps = defaultProps;

export default SelectQuestion;

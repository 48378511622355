import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import { getSectionByUuid } from 'utils/questionnaireHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

const propTypes = {
  uuid: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  questionnaireId: PropTypes.string
};

const defaultProps = {
  prefix: 'NEXT: ',
  disabled: false
};

function SectionLink({
  uuid,
  prefix,
  disabled,
  children,
  questionnaireId,
  questionnaireUuid
}) {
  const section = getSectionByUuid(
    uuid,
    questionnaireUuid || window.localStorage.getItem('questionnaireUuid')
  );

  if (!section) {
    return null;
  }

  return (
    <Button
      tag={Link}
      color="primary"
      size="lg"
      to={section.path.replace(':questionnaireId', questionnaireId)}
      disabled={disabled}
      className="d-inline-flex justify-content-between align-items-center"
    >
      {prefix}
      {children ? children : section.name}{' '}
      <FontAwesomeIcon className="ml-4" size="2x" icon={faCaretRight} />
    </Button>
  );
}

SectionLink.propTypes = propTypes;
SectionLink.defaultProps = defaultProps;

export default SectionLink;

import React, { useEffect } from 'react';
import { isAuthenticated, keycloakLogout } from 'utils/auth';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutUser } from 'reducers';

const propTypes = {};

const defaultProps = {};

function LogoutPage() {
  const authenticated = isAuthenticated();
  const dispatch = useDispatch();

  useEffect(() => {
    if (authenticated) {
      dispatch(logoutUser());
      keycloakLogout();
    }
  });

  return <Redirect to="/login" />;
}

LogoutPage.propTypes = propTypes;
LogoutPage.defaultProps = defaultProps;

export default LogoutPage;

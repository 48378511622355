import React from 'react';
import PropTypes from 'prop-types';
import { questionShape } from 'utils/shapes';
import { FormGroup, Input, FormFeedback } from 'reactstrap';
import QuestionBody from './QuestionBody';
import QuestionLabel from './QuestionLabel';

const propTypes = {
  question: questionShape.isRequired,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  saving: PropTypes.string
};

const defaultProps = {};

function TextQuestion({
  question,
  onChange,
  onSave,
  value,
  disabled,
  saving,
  meta: { touched, errors }
}) {
  const invalid = touched && errors && errors.length > 0;

  return (
    <FormGroup>
      <QuestionLabel question={question} for={question.uuid} saving={saving}>
        {question.summary}
      </QuestionLabel>
      <QuestionBody question={question} />
      <Input
        type={question.responseTypeDef.inputType || 'text'}
        name={question.uuid}
        id={question.uuid}
        onChange={e => onChange && onChange(e.target.value)}
        onBlur={() => onSave && onSave(value)}
        value={value || ''}
        disabled={disabled}
        invalid={invalid}
      />
      {invalid &&
        errors.map(error => (
          <FormFeedback key={`error_${error}`}>{error}</FormFeedback>
        ))}
    </FormGroup>
  );
}

TextQuestion.propTypes = propTypes;
TextQuestion.defaultProps = defaultProps;

export default TextQuestion;

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { entitiesReducer, queriesReducer } from 'redux-query';
import { clearSessionStorage } from 'utils/auth';

const USER_LOGOUT = 'app/USER_LOGOUT';

export function logoutUser() {
  return { type: USER_LOGOUT };
}

export default function createReducer(injectedReducers, history) {
  const appReducer = combineReducers({
    entities: entitiesReducer,
    queries: queriesReducer,
    router: connectRouter(history),
    ...injectedReducers
  });

  return (state, action) => {
    if (action.type === USER_LOGOUT) {
      clearSessionStorage();
      state = undefined;
    }

    return appReducer(state, action);
  };
}

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useRequest } from 'redux-query-react';
import { getQuestionnaireResponses } from 'selectors/adminQuestionnaireResponse';
import { questionnaireResponsesRequest } from 'query-configs/adminQuestionnaireResponse';
import { QUESTIONNAIRE_UUID } from 'utils/constants';
import ReactDataGrid from 'react-data-grid';
import { Toolbar, Data, Filters } from 'react-data-grid-addons';
import LoadingIndicator from 'components/LoadingIndicator';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import startCase from 'lodash/startCase';
import styled from 'styled-components';

const selectors = Data.Selectors;
const {
  NumericFilter,
  AutoCompleteFilter,
  MultiSelectFilter,
  SingleSelectFilter
} = Filters;

const defaultColumnProperties = {
  sortable: true,
  filterable: true
};

const dateFormatter = ({ value }) =>
  value && moment(value).format('MM/DD/YY [-] LT');
const shortDateFormatter = ({ value }) =>
  value && moment(value).format('MM/DD/YY');

const columns = [
  {
    key: 'status',
    name: 'Status',
    formatter: ({ value }) => value && startCase(value.replace('_', ' ')),
    filterRenderer: SingleSelectFilter
  },
  {
    key: 'q-13ff56da-80fd-408c-a91b-920800187d5f',
    name: 'Organization Name'
  },
  {
    key: 'q-eb7f41d2-56df-4d90-aec4-94cf0be5bfe8',
    name: 'Organization Type'
  },
  {
    key: 'firmName',
    name: 'Keycloak Firm'
  },
  {
    key: 'firmType',
    name: 'Keycloak Firm Type'
  },
  {
    key: 'submittedAt',
    name: 'Date Submitted',
    filterable: false,
    sortDescendingFirst: true,
    formatter: dateFormatter
  },
  {
    key: 'updatedAt',
    name: 'Last Status Update',
    filterable: false,
    sortDescendingFirst: true,
    formatter: dateFormatter
  },
  {
    key: 'createdAt',
    name: 'Created',
    filterable: false,
    sortDescendingFirst: true,
    formatter: shortDateFormatter
  }
].map(col => ({ ...defaultColumnProperties, ...col }));

const sortRows = (initialRows, sortColumn, sortDirection) => rows => {
  const comparer = (a, b) => {
    if (sortDirection === 'ASC') {
      return a[sortColumn] > b[sortColumn] ? 1 : -1;
    } else if (sortDirection === 'DESC') {
      return a[sortColumn] < b[sortColumn] ? 1 : -1;
    }
  };
  return sortDirection === 'NONE' ? initialRows : [...rows].sort(comparer);
};

function Applications({ history }) {
  const questionnaireResponse = useSelector(getQuestionnaireResponses);

  const [{ isFinished }] = useRequest(
    questionnaireResponsesRequest(QUESTIONNAIRE_UUID, true, { query: {} })
  );

  if (!isFinished) {
    return <LoadingIndicator />;
  }

  const rows = Object.values(questionnaireResponse).map(element => {
    let answers = element.answers.map(a => {
      return {
        [a.questionUuid]: a.value
      };
    });

    return {
      ...element,
      ...answers[0],
      ...answers[1],
      firmName: element.firmId && element.firmId.name,
      firmType: element.firmId && element.firmId.firmType
    };
  });

  return (
    <Grid
      initialRows={sortRows(rows, 'updatedAt', 'DESC')(rows)}
      push={history.push}
    />
  );
}

const handleFilterChange = filter => filters => {
  const newFilters = { ...filters };
  if (filter.filterTerm) {
    newFilters[filter.column.key] = filter;
  } else {
    delete newFilters[filter.column.key];
  }
  return newFilters;
};

function getValidFilterValues(rows, columnId) {
  return rows
    .map(r => r[columnId])
    .filter((item, i, a) => {
      return i === a.indexOf(item);
    });
}

function getRows(rows, filters) {
  return selectors.getRows({ rows, filters });
}

const Wrapper = styled.div`
  .react-grid-HeaderCell {
    .Select {
      z-index: 50;
    }
  }
`;

function Grid({ initialRows, push }) {
  const [filters, setFilters] = useState({});
  const [rows, setRows] = useState(initialRows);
  const filteredRows = getRows(rows, filters);

  return (
    <Wrapper>
      <h2>Applications</h2>
      <ReactDataGrid
        columns={columns}
        rowGetter={i => filteredRows[i]}
        rowsCount={filteredRows.length}
        minHeight={500}
        headerFiltersHeight={60}
        onRowClick={(rowIdx, row) => {
          if (!row) {
            return;
          }
          push(`/application/${row._id}`);
        }}
        onGridSort={(sortColumn, sortDirection) =>
          setRows(sortRows(initialRows, sortColumn, sortDirection))
        }
        onAddFilter={filter => setFilters(handleFilterChange(filter))}
        onClearFilters={() => setFilters({})}
        getValidFilterValues={columnKey =>
          getValidFilterValues(rows, columnKey)
        }
        toolbar={<Toolbar enableFilter={true} />}
      />
    </Wrapper>
  );
}

export default withRouter(Applications);
